'use client';

import { useStringFieldInfo, useTsController } from '@ts-react/form';
import { useId } from 'react';
import { Fieldset, YStack, XStack } from 'tamagui';
import type { SelectProps } from '../../Select';
import { Select } from '../../Select';
import { Label, type LabelProps } from '../../Label';
import { Popover } from '../../Popover';

export type SelectFieldProps<T> = Pick<
  SelectProps<T>,
  | 'size'
  | 'status'
  | 'disabled'
  | 'hint'
  | 'testID'
  | 'items'
  | 'itemToValue'
  | 'itemToLabel'
  | 'onActiveChange'
  | 'onValueChange'
  | 'variant'
  | 'removePadding'
> & {
  fieldLabel?: string;
  fieldLabelVariant?: LabelProps['variant'];
  info?: { title: string; body: string };
};

export function SelectField<T>({
  size = '$lg',
  status,
  disabled,
  hint,
  testID,
  items,
  itemToValue,
  itemToLabel,
  onActiveChange,
  onValueChange,
  fieldLabel,
  fieldLabelVariant,
  info,
  variant = 'outlined',
  removePadding,
}: SelectFieldProps<T>): JSX.Element {
  const {
    field,
    error, // zod error message
    formState,
  } = useTsController<string>();
  const { isSubmitting } = formState;
  const { label, placeholder } = useStringFieldInfo();
  const id = useId();
  const isDisabled = disabled || isSubmitting;

  const typedItems: T[] = items as T[];

  return (
    <Fieldset>
      <YStack gap="$lg">
        {fieldLabel ? (
          <XStack gap="$sm" alignItems="center">
            {fieldLabel ? <Label variant={fieldLabelVariant}>{fieldLabel}</Label> : null}
            {info ? (
              <Popover title={info.title} targetElement="info">
                {info.body}
              </Popover>
            ) : null}
          </XStack>
        ) : null}
        <Select
          ref={field.ref}
          id={id}
          size={size}
          testID={`${testID || field.name}`}
          disabled={isDisabled}
          label={label}
          placeholder={placeholder}
          // key={field.value}
          value={field.value ?? ''} // default empty string to prevent "uncontrolled to controlled" react warning
          error={error?.errorMessage}
          hint={hint}
          status={status}
          items={typedItems}
          itemToValue={itemToValue}
          itemToLabel={itemToLabel}
          onActiveChange={(value, index) => {
            onActiveChange?.(value, index);
          }}
          onValueChange={(value) => {
            field.onChange(value);
            void onValueChange?.(value);
          }}
          variant={variant}
          removePadding={removePadding}
        />
      </YStack>
    </Fieldset>
  );
}

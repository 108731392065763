import { focusFocusable } from '@tamagui/focusable';
import type { GetProps, ReactComponentWithRef } from '@tamagui/web';
import { isWeb, styled, themeable, useComposedRefs } from '@tamagui/web';
import { createContext } from 'tamagui';
import * as React from 'react';
import type { View } from 'react-native';
import { Text } from '../Text';

const NAME = 'Label';

interface LabelContextValue {
  id?: string;
  controlRef: React.MutableRefObject<HTMLElement | null>;
}

const [LabelProvider, useLabelContextImpl] = createContext<LabelContextValue>(NAME, {
  id: undefined,
  controlRef: { current: null },
});

export const LabelFrame = styled(Text, {
  name: 'Label',
  tag: 'label',

  variants: {
    variant: {
      bodyMediumEm: {
        variant: 'bodyMediumEm',
      },
      bodySmall: {
        variant: 'bodySmall',
      },
      bodyMedium: {
        variant: 'bodyMedium',
      },
      heading: {
        tag: 'h3',
        fontFamily: '$heading',
        fontSize: '$xl',
        lineHeight: '$xl',
        fontWeight: '$xl',
        $ltTablet: {
          fontSize: '$lg',
          lineHeight: '$lg',
          fontWeight: '$lg',
        },
      },
    },
  } as const,

  defaultVariants: {},
});

export type LabelProps = GetProps<typeof LabelFrame> & {
  htmlFor?: string;
};

const LabelComponent = React.forwardRef<typeof LabelFrame, LabelProps>((props, forwardedRef) => {
  const { htmlFor, id: idProp, variant, ...labelProps } = props;
  const controlRef = React.useRef<HTMLElement | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- from the Tamagui implementation
  const ref = React.useRef<any>(null);
  const composedRefs = useComposedRefs(forwardedRef, ref);
  const backupId = React.useId();
  const id = idProp ?? backupId;

  React.useEffect(() => {
    if (isWeb) {
      if (htmlFor) {
        const element = document.getElementById(htmlFor);
        const label = ref.current;
        if (label && element) {
          const getAriaLabel = () => element.getAttribute('aria-labelledby');
          const ariaLabelledBy = [id, getAriaLabel()].filter(Boolean).join(' ');
          element.setAttribute('aria-labelledby', ariaLabelledBy);
          controlRef.current = element;
          return () => {
            /**
             * We get the latest attribute value because at the time that this cleanup fires,
             * the values from the closure may have changed.
             */
            if (!id) {
              return;
            }
            const retAriaLabelledBy = getAriaLabel()?.replace(id, '');
            if (retAriaLabelledBy === '') {
              element.removeAttribute('aria-labelledby');
            } else if (retAriaLabelledBy) {
              element.setAttribute('aria-labelledby', retAriaLabelledBy);
            }
          };
        }
      }
      return undefined;
    }
    return undefined;
  }, [id, htmlFor]);

  return (
    <LabelProvider id={id} controlRef={controlRef}>
      <LabelFrame
        id={id}
        // @ts-expect-error -- htmlFor does not exist in frame but we're forcing the attribute
        htmlFor={htmlFor}
        variant={variant}
        {...labelProps}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- from Tamagui implementation
        ref={composedRefs as any}
        onMouseDown={(event) => {
          props.onMouseDown?.(event);
          // prevent text selection when double clicking label
          if (!event.defaultPrevented && event.detail > 1) {
            event.preventDefault();
          }
        }}
        onPress={(event) => {
          props.onPress?.(event);

          if (isWeb) {
            if (htmlFor || !controlRef.current || event.defaultPrevented) {
              return;
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- from Tamagui implementation
            const isClickingControl = controlRef.current.contains(event.target as any as Node);
            // Ensure event was generated by a user action
            // https://developer.mozilla.org/en-US/docs/Web/API/Event/isTrusted
            const isUserClick = event.isTrusted;
            /**
             * When a label is wrapped around the control it labels, we trigger the appropriate events
             * on the control when the label is clicked. We do nothing if the user is already clicking the
             * control inside the label.
             */
            if (!isClickingControl && isUserClick) {
              controlRef.current.click();
              controlRef.current.focus();
            }
          } else if (props.htmlFor) {
            focusFocusable(props.htmlFor);
          }
        }}
      />
    </LabelProvider>
  );
});

LabelComponent.displayName = NAME;

export const Label: ReactComponentWithRef<LabelProps, HTMLButtonElement | View> = LabelFrame.extractable(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- from Tamagui implementation
  themeable(LabelComponent as any) as any,
  {
    neverFlatten: true,
  }
);

export const useLabelContext = (element?: HTMLElement | null) => {
  const context = useLabelContextImpl('LabelConsumer');
  const { controlRef } = context;

  React.useEffect(() => {
    if (element) {
      controlRef.current = element;
    }
  }, [element, controlRef]);

  return context.id;
};

/* eslint-disable @typescript-eslint/no-explicit-any -- any used for generic */
/* eslint-disable @typescript-eslint/no-unsafe-member-access -- any used for generic */

import type { RefObject } from 'react';
import { useRef, useState, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { YStack } from 'tamagui';
import { Button } from '../Button/Button';
import type { TaskRef } from './withTaskRef';
import type { CDPage, ComponentMap, ComponentProps } from './types';

interface MultiTaskPageProps {
  tasks: CDPage[];
  componentMap: ComponentMap;
  props: ComponentProps & {
    onUpdate: (x: unknown, dataId: string) => Promise<void> | void;
    config: object[];
  };
  finalStep: boolean;
  disabled?: boolean;
}

export const MultiTaskPage = ({ tasks, componentMap, props, finalStep, disabled }: MultiTaskPageProps) => {
  const { t } = useTranslation();

  // Create refs array for tasks
  const taskRefs = useRef<RefObject<TaskRef>[]>(tasks.map(() => createRef<TaskRef>()));
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  return (
    <YStack gap="$4xl" $tablet={{ marginVertical: '$8xl' }}>
      {tasks.map((task, index) => {
        return componentMap[task.name]?.component.multiTask(
          {
            ...props,
            // Use props.onUpdate so that we are not calling the onSubmit of the multi step form.
            // It is labelled 'onSubmit' to allow the task to be reusable in non multi task pages too.
            onSubmit: (x: unknown) => props.onUpdate(x, task.dataId),
            config: props.config[index],
            initialValues: task.initialValues,
          },
          taskRefs.current[index]
        );
      })}
      <YStack alignSelf="flex-start">
        <Button
          mode="primary"
          variant="filled"
          size="lg"
          disabled={isSubmitting || disabled}
          onPress={async () => {
            try {
              setIsSubmitting(true);
              await Promise.all(
                // Trigger each child submit function
                taskRefs.current.map((ref) => {
                  if (ref.current?.onSubmit) {
                    return ref.current.onSubmit();
                  }
                  return Promise.resolve(); // Handle cases where onSubmit is undefined
                })
              );
              // Only proceed if all submissions succeeded
              await props.onSubmit();
            } catch {
              // Do nothing. Error handling will be informed in the individual tasks.
            } finally {
              setIsSubmitting(false);
            }
          }}
          loading={isSubmitting}
        >
          {/* Show submit if it is the final step in the flow */}
          {finalStep ? t('common.buttons.submit') : t('common.buttons.continue')}
        </Button>
      </YStack>
    </YStack>
  );
};

import type { ReactNode } from 'react';
import { Check } from '@tamagui/lucide-icons';
import { useFieldInfo, useTsController } from '@ts-react/form';
import { useId } from 'react';
import { Fieldset, XStack, Stack, Label } from 'tamagui';
import type { CheckboxProps, CheckedState } from 'tamagui';
import { Platform } from 'react-native';
import { Checkbox } from '../../Checkbox';
import { Alert } from '../../Alert';

export interface BooleanCheckboxFieldProps extends Pick<CheckboxProps, 'testID' | 'disabled'> {
  outlined?: boolean;
  children?: ReactNode;
}

export const BooleanCheckboxField = ({
  testID,
  disabled = false,
  outlined = true,
  children,
}: BooleanCheckboxFieldProps) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<CheckedState>();
  const { label } = useFieldInfo();
  const id = useId();
  const isDisabled = isSubmitting || disabled;

  // Required on native only
  const toggleCheckBox = () => {
    field.onChange(!field.value);
  };

  return (
    <Fieldset gap="$lg">
      <XStack
        gap={12}
        alignItems="center"
        padding={outlined ? '$lg' : null}
        borderWidth={outlined ? 1 : undefined}
        borderRadius="$radius.sm"
        borderColor={
          error ? '$checkbox/color/checkbox-card-border-danger' : '$checkbox/color/checkbox-card-border-default'
        }
        overflow="hidden"
        // FIXME - Added in to continue with flow
        flex={Platform.OS === 'web' ? 1 : undefined}
        minWidth="100%"
      >
        <Checkbox
          ref={field.ref}
          id={id}
          testID={`${testID || field.name}-checkbox`}
          disabled={isDisabled}
          checked={field.value}
          onCheckedChange={(checked) => {
            field.onChange(checked);
          }}
          isError={!!error}
        >
          <Checkbox.Indicator>
            <Check
              color={disabled ? '$checkbox/color/checkbox-border-default' : '$checkbox/color/checkbox-icon-selected'}
            />
          </Checkbox.Indicator>
        </Checkbox>

        {children ? (
          <Label
            lineHeight={20}
            testID={`${testID || field.name}-label`}
            htmlFor={id}
            cursor="pointer"
            onPress={Platform.OS !== 'web' ? toggleCheckBox : undefined}
          >
            {children}
          </Label>
        ) : (
          <Label
            testID={`${testID || field.name}-label`}
            htmlFor={id}
            cursor="pointer"
            color="$form/color/form-fg-default"
            lineHeight={20}
            fontSize="$sm"
            whiteSpace="pre-wrap"
            flex={Platform.OS === 'web' ? 1 : undefined}
            onPress={Platform.OS !== 'web' ? toggleCheckBox : undefined}
          >
            {label}
          </Label>
        )}
      </XStack>
      {error?.errorMessage ? (
        <Stack marginTop="$md">
          <Alert severity="danger" variant="inline-transparent">
            {error.errorMessage}
          </Alert>
        </Stack>
      ) : null}
    </Fieldset>
  );
};

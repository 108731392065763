'use client';

import type { GetThemeValueForKey } from '@tamagui/web';
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

export interface PageFrameContextValue {
  headerHeight: number;
  setHeaderHeight: (height: number) => void;
  footerHeight: number;
  setFooterHeight: (height: number) => void;
  backgroundColor?: GetThemeValueForKey<'backgroundColor'>;
}

export const PageFrameContext = createContext<PageFrameContextValue | undefined>(undefined);

export const PageFrameProvider = ({
  children,
  backgroundColor,
}: {
  children: ReactNode;
  backgroundColor?: GetThemeValueForKey<'backgroundColor'>;
}) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  const value = useMemo(() => {
    return { headerHeight, setHeaderHeight, footerHeight, setFooterHeight, backgroundColor };
  }, [headerHeight, setHeaderHeight, footerHeight, setFooterHeight, backgroundColor]);

  return <PageFrameContext.Provider value={value}>{children}</PageFrameContext.Provider>;
};

export const usePageFrameContext = () => {
  const context = useContext(PageFrameContext);
  if (context === undefined) {
    throw new Error('usePageFrameContext must be used within a PageFrameProvider');
  }
  return context;
};

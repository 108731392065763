/* eslint-disable @typescript-eslint/no-explicit-any -- any used for generic */
'use client';

import type { Steps } from '../MultiStepForm';
import type { ComponentMap, CDStep, ComponentSubmitDataId, CDPage, MultiTaskCDPage } from './types';
import { MultiTaskPage } from './MultiTaskPage';

export function mapBEStepsToMultiFormSteps({
  flowId,
  componentMap,
  beSteps,
  onComponentSubmit,
  onTaskComplete,
  disabled,
  isSubmitting,
  application,
}: {
  flowId: string;
  componentMap: ComponentMap;
  beSteps: CDStep[];
  onComponentSubmit: ComponentSubmitDataId;
  onTaskComplete: () => Promise<void>;
  disabled?: boolean;
  isSubmitting?: boolean;
  application: any;
}): Steps<React.FC<any>[][]> {
  return beSteps.map((s, stepIndex, stepArray) => ({
    id: s.id,
    name: s.name,
    steps: s.steps.map((p, subStepIndex, subStepArray) => {
      if (isMultiTaskPage(p)) {
        return {
          id: p.id,
          name: p.requestContext.displayName,
          component: (props) => {
            return (
              <MultiTaskPage
                tasks={p.requestContext.tasks}
                componentMap={componentMap}
                props={props}
                finalStep={stepIndex === stepArray.length - 1 && subStepIndex === subStepArray.length - 1}
                disabled={disabled}
              />
            );
          },
          props: {
            // onSubmit is not used because the prop updates the multi step form to the next page.
            onSubmit: () => onComponentSubmit(),
            config: p.requestContext.tasks.map((task) => {
              return task.requestContext;
            }),
            flowId,
            onUpdate: (x: unknown, dataId: string) => onComponentSubmit(x, dataId),
            application,
          },
          completed: p.completed,
          locked: p.locked,
        };
      }

      const comp = componentMap[p.name];

      if (!comp) {
        throw new Error(`Component not found for ${p.name}`);
      }

      const onUpdateFlow = async (x: unknown) => {
        await onComponentSubmit(x, p.dataId);
        // Invalidate and refetch queries
        await onTaskComplete();
      };

      return {
        id: p.id,
        name: p.requestContext?.displayName ?? comp.name,
        component: comp.component.normal, // No task ref given
        props: {
          onSubmit: onUpdateFlow,
          onUpdate: onUpdateFlow,
          config: p.requestContext,
          initialValues: p.initialValues,
          flowId,
          application,
          isSubmitting,
        },
        completed: p.completed,
        locked: p.locked,
        fullwidth: !!comp.fullWidth,
      };
    }),
  }));
}

// Safe casting
function isMultiTaskPage(page: CDPage): page is MultiTaskCDPage {
  return page.name === 'multitask';
}

'use client';

import type { GetProps, StackProps, Token } from '@tamagui/core';
import { getTokenValue, isWeb, styled } from '@tamagui/core';
import { XStack, YStack, Stack } from 'tamagui';
import { TextInput } from 'react-native';
import { cloneElement, isValidElement, useState, type ReactElement } from 'react';
import { useFocusable } from '@tamagui/focusable';
import type { IconProps } from '@tamagui/helpers-icon';
import { XCircle } from '@tamagui/lucide-icons';
import Svg, { Line } from 'react-native-svg';
import { Label, type LabelProps } from '../Label';

type ReturnedInputProps = InputSingleBaseProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this one comes from the original Tamagui component
  ref: any;
  editable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this one comes from the original Tamagui component
  onChangeText: (text: string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- this one comes from the original Tamagui component
function useInputProps(props: InputSingleBaseProps, ref: any): ReturnedInputProps {
  const { onChangeText, ref: combinedRef } = useFocusable({
    props,
    ref,
    isInput: true,
  });

  return {
    ref: combinedRef,
    editable: !props.disabled,
    readOnly: !!props.disabled,
    ...props,
    onChangeText,
  };
}
/* -------------------------------------------------------------------------------------------------
 * Base Input Component
 * - Optional start and end icons
 * -----------------------------------------------------------------------------------------------*/

const getInputBaseVariantTokens = () => {
  return {
    fontSize: '$lg',
    fontWeight: '$lg',
    fontFamily: '$bodyEmphasised',
    height: '$size.input/size/md',
  } as const;
};

export const InputSingleBaseFrame = styled(TextInput, {
  name: 'InputSingleBase',
  fontFamily: '$bodyEmphasised',
  color: '$form/color/form-fg-default',
  backgroundColor: '$background/transparent',
  placeholderTextColor: '$form/color/form-fg-subdued',
  borderBottomEndRadius: 0,
  borderBottomStartRadius: 0,
  borderTopStartRadius: 0,
  borderTopEndRadius: 0,
  minWidth: 0,
  paddingTop: 0,
  paddingBottom: 0,
  margin: 0,
  ...(isWeb
    ? {
        tabIndex: 0,
      }
    : {
        focusable: true,
      }),
  variants: {
    size: {
      // Since we are having only one size for InputSingle, we can just return the same tokens for all sizes
      $sm: getInputBaseVariantTokens(),
      $md: getInputBaseVariantTokens(),
      $lg: getInputBaseVariantTokens(),
      $xl: getInputBaseVariantTokens(),
    },
    disabled: {
      true: {
        color: '$foreground/disabled',
      },
    },
  } as const,

  defaultVariants: {
    size: '$lg',
  },
});

export interface InputSingleBaseFrameExtraProps {
  label?: string;
  labelProps?: Omit<LabelProps, 'ref'>;
  inlineLabel?: boolean;
  fieldLabel?: string;
  startIcon?: ReactElement;
  startIconContainerProps?: StackProps;
  endIcon?: ReactElement;
  endIconContainerProps?: StackProps;
  /**
   * There is a specific bug with Tamagui where nested styled/styleable is dropping disabled along the way.
   * Specifically, any use of styled() on InputBase, that parent component will not pass disabled properly to InputBase, despite what the docs say.
   * Creating a prop which will just pass through this value to disabled
   */
  inputDisabled?: boolean;
  /**
   * Prefix to visually display at the start of the input field.
   *
   * It **will not** transform the input value in any way.
   *
   * If provided, startIcon will be ignored.
   */
  prefix?: string;
  info?: { title: string; body: string };
  hasError?: boolean;
}

type InputSingleBaseFrameBaseProps = GetProps<typeof InputSingleBaseFrame>;

export type InputSingleBaseProps = InputSingleBaseFrameBaseProps & InputSingleBaseFrameExtraProps;

export const InputSingleBase = InputSingleBaseFrame.styleable<InputSingleBaseFrameExtraProps>(
  (
    {
      label,
      labelProps,
      inlineLabel = true,
      endIcon,
      endIconContainerProps,
      inputDisabled,
      prefix,
      startIcon,
      hasError = false,
      startIconContainerProps,
      ...props
    },
    ref
  ) => {
    // Parse input related props, ignore any extra props
    const { placeholder, disabled, value, onChangeText, ...inputProps } = useInputProps(
      { ...props, disabled: inputDisabled },
      ref
    );

    const [isFocused, setIsFocused] = useState<boolean>(false);

    const showLabel = value?.length && value.length > 0;

    return (
      <YStack>
        <YStack height="$space.lg" width="100%">
          <Label
            {...labelProps}
            variant="bodySmall"
            color="$form/color/form-fg-subdued"
            lineHeight="unset"
            selectable={false}
          >
            {showLabel ? label : ''}
          </Label>
        </YStack>

        <XStack minWidth="100%" alignItems="center" gap="$xs" justifyContent="flex-start">
          {startIcon && !prefix && isValidElement<IconProps>(startIcon) ? (
            <YStack {...startIconContainerProps} alignItems="center" justifyContent="center">
              {cloneElement(startIcon, {
                size: '$icon/size/xs',
                // If input is disabled, change the color of the provided icon
                color: disabled
                  ? '$form/color/form-fg-subdued'
                  : startIcon.props.color ?? '$form/color/form-fg-default',
              })}
            </YStack>
          ) : null}
          <Stack flex={1} position="relative">
            <InputSingleBaseFrame
              disabled={disabled}
              value={value}
              placeholder={inlineLabel && label && !placeholder && !prefix ? label : placeholder}
              onChangeText={(text: string) => {
                onChangeText(text);
              }}
              {...inputProps}
              onFocus={() => {
                setIsFocused(true);
              }}
              onBlur={() => {
                setIsFocused(false);
              }}
              size="$lg"
            />
            <DottedUnderLine error={hasError} hidden={isFocused} disabled={disabled} />
          </Stack>
          {value || disabled ? (
            <YStack
              disabled={disabled}
              {...endIconContainerProps}
              alignItems="center"
              justifyContent="center"
              onPress={() => {
                onChangeText('');
              }}
            >
              <XCircle size="$icon/size/xs" color={disabled ? '$form/color/form-fg-subdued' : '$icon/primary'} />
            </YStack>
          ) : null}

          {!value && !disabled && endIcon && isValidElement<IconProps>(endIcon) ? (
            <YStack {...endIconContainerProps} alignItems="center" justifyContent="center">
              {cloneElement(endIcon, {
                size: '$icon/size/xs',
                color: endIcon.props.color ?? '$form/color/form-fg-default',
              })}
            </YStack>
          ) : null}
        </XStack>
      </YStack>
    );
  }
);

const DottedUnderLine = ({
  error = false,
  hidden = false,
  disabled = false,
}: {
  error: boolean;
  hidden: boolean;
  disabled: boolean | undefined;
}) => {
  if (hidden) {
    return null;
  }
  let dotColor = '$border/primary';

  if (error) {
    dotColor = '$border/danger';
  } else if (disabled) {
    dotColor = '$border/disabled';
  }

  return (
    <Stack position="absolute" bottom="$space.input/space/vertical-padding-sm" right={0} left={0}>
      <Svg height="3" width="100%">
        <Line
          x1="1.5"
          y1="1.5"
          x2="100%"
          y2="1.5"
          stroke={getTokenValue(dotColor as Token, 'color') as string}
          strokeWidth="3"
          strokeLinecap="round"
          strokeDasharray="0.1 6"
        />
      </Svg>
    </Stack>
  );
};

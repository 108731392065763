import { ChevronDown } from '@tamagui/lucide-icons';
import { isValidElement } from 'react';
import { type GetProps, Square } from 'tamagui';
import { Text } from '../Text';
import { Accordion } from './Accordion';

interface AccordionData {
  header: JSX.Element | string;
  content: JSX.Element | string;
  itemValue?: string;
  endIcon?: JSX.Element;
}

type AccordionListProps = {
  data: AccordionData[];
  itemProps?: Omit<GetProps<typeof Accordion.Item>, 'value'>;
  triggerProps?: GetProps<typeof Accordion.Trigger>;
  contentProps?: GetProps<typeof Accordion.Content>;
} & GetProps<typeof Accordion>;

export const renderDefaultAccordionHeader = (title: JSX.Element | string, endIcon = <ChevronDown />) =>
  function DefaultAccordionHeader({ open }: { open: boolean }) {
    return (
      <>
        <Text variant="bodyMediumEm" color="$text/surface">
          {title}
        </Text>
        <Square animation="quick" rotate={open ? '180deg' : '0deg'}>
          {endIcon}
        </Square>
      </>
    );
  };

export const AccordionTextContent = ({ content }: { content: JSX.Element | string }) => (
  <Text variant="bodyMedium" color="$text/surface-subdued" whiteSpace="pre-wrap">
    {content}
  </Text>
);

export const AccordionList = (props: AccordionListProps) => {
  const { data, itemProps, triggerProps, contentProps, ...rest } = props;

  return (
    <Accordion {...rest}>
      {data.map((accordionItem, index) => (
        <Accordion.Item
          value={accordionItem.itemValue || `item-${index}`}
          key={`accordion-item-${index}`}
          backgroundColor="$background/transparent"
          borderColor="$border/surface-subdued"
          borderRadius="$surface/radius/surface-radius"
          borderWidth="$size.input/size/border-width"
          overflow="hidden"
          {...itemProps}
        >
          <Accordion.Trigger
            flexDirection="row"
            justifyContent="space-between"
            unstyled
            borderColor="$background/transparent"
            backgroundColor="$background/transparent"
            padding="$space.lg"
            cursor="pointer"
            {...triggerProps}
          >
            {isValidElement(accordionItem.header)
              ? accordionItem.header
              : renderDefaultAccordionHeader(accordionItem.header)}
          </Accordion.Trigger>
          <Accordion.Content
            unstyled
            borderColor="$background/transparent"
            backgroundColor="$background/transparent"
            paddingHorizontal="$space.lg"
            paddingBottom="$space.lg"
            {...contentProps}
          >
            {isValidElement(accordionItem.content) ? (
              accordionItem.content
            ) : (
              <AccordionTextContent content={accordionItem.content} />
            )}
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

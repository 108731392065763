'use client';

import { useStringFieldInfo, useTsController } from '@ts-react/form';
import { useId } from 'react';
import type { XStackProps } from 'tamagui';
import { Fieldset, XStack, YStack } from 'tamagui';
import { RadioGroup } from '../../RadioGroup';
import { Label, type LabelProps } from '../../Label';
import { Alert } from '../../Alert';
import { Text } from '../../Text';
import { Popover } from '../../Popover';

export interface SelectRadioFieldOptions {
  label: string;
  subtitle?: string;
  value: string;
}

export interface SelectRadioFieldProps {
  options: SelectRadioFieldOptions[];
  fieldContainerProps?: XStackProps;
  variant?: 'outlined' | 'no-outline';
  onValueChange?: (value: string) => void;
  disabled?: boolean;
  fieldLabelVariant?: LabelProps['variant'];
  info?: { title: string; body: string };
}

export function SelectRadioField({
  options,
  variant = 'no-outline',
  fieldContainerProps,
  onValueChange,
  fieldLabelVariant,
  info,
  disabled = false,
}: SelectRadioFieldProps): JSX.Element {
  const {
    field,
    error, // zod error message
    formState,
  } = useTsController<string>();
  const { isSubmitting } = formState;
  const zodFieldInfo = useStringFieldInfo();
  const { label } = zodFieldInfo;
  const labelId = useId();
  const isDisabled = isSubmitting || disabled;
  return (
    <Fieldset gap="$lg">
      {Boolean(label) && (
        <XStack gap="$sm" alignItems="center">
          <Label variant={fieldLabelVariant}>{label}</Label>
          {info ? (
            <Popover title={info.title} targetElement="info">
              <Text variant="bodySmall" color="$text/surface-inverse">
                {info.body}
              </Text>
            </Popover>
          ) : null}
        </XStack>
      )}

      {error?.errorMessage ? (
        <Alert severity="danger" variant="inline-transparent">
          {error.errorMessage}
        </Alert>
      ) : null}
      <RadioGroup
        ref={field.ref}
        aria-labelledby={labelId}
        disabled={isDisabled}
        value={field.value}
        onValueChange={(value) => {
          field.onChange(value);
          onValueChange?.(value);
        }}
      >
        <YStack gap="$lg">
          {options.map((item) => (
            <XStack
              key={item.value}
              alignItems="center"
              gap="$lg"
              borderWidth={variant === 'outlined' ? 1 : undefined}
              borderColor={
                field.value === item.value
                  ? '$radio/color/radio-card-border-selected'
                  : '$radio/color/radio-card-border-default'
              }
              padding={variant === 'outlined' ? '$lg' : undefined}
              borderRadius={variant === 'outlined' ? '$form/radius/formcontrol' : undefined}
              backgroundColor={
                variant === 'outlined' && field.value === item.value ? '$radio/color/radio-card-bg-selected' : undefined
              }
              {...fieldContainerProps}
            >
              <RadioGroup.Item
                value={item.value}
                id={`option-${item.value}`}
                borderColor={
                  field.value === item.value
                    ? '$radio/color/radio-border-selected'
                    : '$radio/color/radio-border-default'
                }
                borderWidth={field.value === item.value ? 6 : 1}
                cursor="pointer"
              />
              <YStack overflow="hidden" maxWidth="90%">
                <Label
                  variant="bodyMedium"
                  whiteSpace="normal"
                  wordWrap="break-word"
                  htmlFor={`option-${item.value}`}
                  color={
                    variant === 'outlined' && field.value === item.value
                      ? '$radio/color/radio-fg-selected'
                      : '$radio/color/radio-fg-default'
                  }
                  cursor="pointer"
                >
                  {item.label}
                </Label>
                {item.subtitle ? (
                  <Label variant="bodySmall" whiteSpace="normal" wordWrap="break-word">
                    {item.subtitle}
                  </Label>
                ) : null}
              </YStack>
            </XStack>
          ))}
        </YStack>
      </RadioGroup>
    </Fieldset>
  );
}

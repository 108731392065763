'use client';

import { useNumberFieldInfo, useTsController } from '@ts-react/form';
import { useId } from 'react';
import { Fieldset, YStack } from 'tamagui';
import type { InputProps } from '../../Input';
import { Input } from '../../Input';

export type NumberFieldProps = Pick<
  InputProps,
  | 'size'
  | 'status'
  | 'disabled'
  | 'hint'
  | 'testID'
  | 'fieldLabel'
  | 'fieldLabelVariant'
  | 'endIcon'
  | 'startIcon'
  | 'info'
>;

// Does not support decimal values
export function NumberField({
  size = '$lg',
  status,
  disabled,
  hint,
  testID,
  fieldLabel,
  fieldLabelVariant,
  endIcon,
  startIcon,
  info,
}: NumberFieldProps): JSX.Element {
  const {
    field,
    error, // zod error message
    formState,
  } = useTsController<number>();
  const { isSubmitting } = formState;
  const { label, placeholder } = useNumberFieldInfo();
  const id = useId();
  const isDisabled = disabled || isSubmitting;

  const convertValue = (value: number) => {
    if (value !== 0 && !Number(value)) {
      return '';
    }

    return Intl.NumberFormat('en-EN').format(Number(value));
  };

  const displayValue = field.value !== undefined ? convertValue(field.value) : '';
  return (
    <Fieldset>
      <YStack>
        <Input
          ref={field.ref}
          id={id}
          size={size}
          testID={`${testID || field.name}-input`}
          disabled={isDisabled}
          inputMode="numeric"
          onBlur={field.onBlur}
          onChangeText={(value) => {
            // Strip conversion
            const text = value.replace(/,/g, '');
            if (text === '') {
              field.onChange('' as unknown as number);
              return;
            }
            if (isNaN(Number(text)) || Number(text) > Number.MAX_SAFE_INTEGER) {
              return;
            }
            field.onChange(Number(text));
          }}
          label={label ? label : undefined}
          fieldLabel={fieldLabel ? fieldLabel : undefined}
          fieldLabelVariant={fieldLabelVariant}
          placeholder={placeholder}
          value={displayValue}
          error={error?.errorMessage}
          hint={hint}
          status={status}
          startIcon={startIcon}
          endIcon={endIcon}
          info={info}
        />
      </YStack>
    </Fieldset>
  );
}

'use client';

import { useNumberFieldInfo, useTsController } from '@ts-react/form';
import { useId } from 'react';
import { Fieldset, YStack } from 'tamagui';
import { Platform } from 'react-native';
import type { InputProps } from '../../Input';
import { Input } from '../../Input';

export type CurrencyFieldProps = Pick<
  InputProps,
  'size' | 'status' | 'disabled' | 'hint' | 'testID' | 'fieldLabel' | 'fieldLabelVariant' | 'info'
>;

export function CurrencyField({
  size = '$lg',
  status,
  disabled,
  hint,
  testID,
  fieldLabel,
  fieldLabelVariant,
  info,
}: CurrencyFieldProps): JSX.Element {
  const { field, error, formState } = useTsController<number>();
  const { isSubmitting } = formState;
  const zodFieldInfo = useNumberFieldInfo();
  const { label, placeholder } = zodFieldInfo;
  const id = useId();
  const isDisabled = disabled || isSubmitting;

  const dispatchValue = (value: string) => {
    // Strip conversion
    const newValue = value.replace(/,/g, '');

    // Do not update value if a value is given that is not a number
    if (value !== '' && isNaN(Number(newValue))) {
      return;
    }

    // Do not update value if its over max safe value
    if (Number(newValue) > Number.MAX_SAFE_INTEGER) {
      return;
    }

    field.onChange(newValue ? Number(newValue) : NaN);
  };

  const convertValue = (value: number) => {
    if (value !== 0 && !Number(value)) {
      return '';
    }

    return Intl.NumberFormat('en-EN').format(Number(value));
  };

  const displayValue = field.value !== undefined ? convertValue(field.value) : '';

  const keyboardType = Platform.OS === 'web' ? undefined : 'decimal-pad';

  return (
    <Fieldset>
      <YStack>
        <Input
          ref={field.ref}
          id={id}
          size={size}
          testID={`${testID || field.name}-input`}
          disabled={isDisabled}
          keyboardType={keyboardType}
          onBlur={field.onBlur}
          onChangeText={(text) => {
            dispatchValue(text);
          }}
          label={label ? label : undefined}
          fieldLabel={fieldLabel ? fieldLabel : undefined}
          fieldLabelVariant={fieldLabelVariant}
          placeholder={placeholder}
          value={displayValue}
          error={error?.errorMessage}
          hint={hint}
          status={status}
          prefix="$"
          info={info}
        />
      </YStack>
    </Fieldset>
  );
}

'use client';

import { YStack } from 'tamagui';
import type { ReactNode } from 'react';
import { usePageFrameContext } from './PageFrameContext';

export interface PageFrameFooterProps {
  children: ReactNode;
}

export const PageFrameFooter = ({ children }: PageFrameFooterProps) => {
  const { setFooterHeight } = usePageFrameContext();

  return (
    <YStack
      alignItems="center"
      gap="$lg"
      width="100%"
      marginTop="$2xl"
      onLayout={(e) => {
        setFooterHeight(Math.round(e.nativeEvent.layout.height));
      }}
      paddingHorizontal="$xl"
    >
      {children}
    </YStack>
  );
};

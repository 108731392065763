import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.11896 3.91002C8.04524 2.50647 10.4095 1.83706 12.7856 2.02243C15.1618 2.20781 17.3936 3.23577 19.0789 4.92107C20.7642 6.60638 21.7922 8.83821 21.9776 11.2144C22.163 13.5905 21.4935 15.9548 20.09 17.8811C18.6864 19.8073 16.641 21.169 14.3224 21.7206C12.1442 22.2388 9.85945 22.0102 7.83253 21.0788L2.32107 22.9471C1.96061 23.0692 1.56205 22.9762 1.29292 22.7071C1.02379 22.438 0.930774 22.0394 1.05296 21.6789L2.92125 16.1675C1.98982 14.1406 1.7612 11.8558 2.2794 9.67765C2.83103 7.35898 4.19268 5.31358 6.11896 3.91002ZM12.6301 4.01637C10.7292 3.86808 8.83776 4.4036 7.29674 5.52645C5.75571 6.64929 4.6664 8.28561 4.2251 10.1405C3.78379 11.9955 4.01951 13.947 4.88979 15.6436C5.01328 15.8843 5.03395 16.1648 4.94709 16.421L3.5974 20.4026L7.57899 19.0529C7.83522 18.9661 8.11572 18.9867 8.35645 19.1102C10.053 19.9805 12.0045 20.2162 13.8595 19.7749C15.7144 19.3336 17.3507 18.2443 18.4736 16.7033C19.5964 15.1623 20.1319 13.2709 19.9836 11.3699C19.8353 9.46899 19.013 7.68353 17.6647 6.33529C16.3165 4.98704 14.531 4.16467 12.6301 4.01637Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2581 8.02431C11.7926 7.94446 11.3139 8.03194 10.9067 8.27125C10.4995 8.51057 10.1901 8.88628 10.0334 9.33183C9.85009 9.85282 9.27917 10.1266 8.75818 9.94332C8.23719 9.76005 7.96342 9.18913 8.14669 8.66814C8.46016 7.77703 9.0789 7.02562 9.8933 6.54699C10.7077 6.06836 11.6652 5.89339 12.5963 6.05309C13.5273 6.21279 14.3718 6.69684 14.9801 7.41951C15.5884 8.14204 15.9213 9.05647 15.92 10.0009C15.9196 11.5313 14.785 12.5419 13.9747 13.082C13.5391 13.3725 13.1106 13.586 12.7949 13.7263C12.6357 13.7971 12.5016 13.8508 12.4049 13.8876C12.3565 13.906 12.3173 13.9203 12.2887 13.9305L12.254 13.9426L12.2429 13.9464L12.239 13.9477L12.2375 13.9483C12.2375 13.9483 12.2363 13.9487 11.92 13L12.2363 13.9487C11.7123 14.1233 11.146 13.8402 10.9713 13.3162C10.7968 12.7926 11.0795 12.2267 11.6028 12.0517L11.6015 12.0521L11.6028 12.0517L11.6187 12.0461C11.6341 12.0406 11.6593 12.0314 11.6929 12.0186C11.7604 11.9929 11.8606 11.9529 11.9826 11.8987C12.2295 11.789 12.551 11.6275 12.8653 11.4179C13.5549 10.9582 13.92 10.4691 13.92 9.99999L13.92 9.9985C13.9207 9.52618 13.7543 9.06885 13.4501 8.70752C13.1459 8.34618 12.7237 8.10415 12.2581 8.02431Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17C11 16.4477 11.4477 16 12 16H12.01C12.5623 16 13.01 16.4477 13.01 17C13.01 17.5523 12.5623 18 12.01 18H12C11.4477 18 11 17.5523 11 17Z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'ChatHelp';

export const ChatHelp = memo<IconProps>(themed(Icon));

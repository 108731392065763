import React from 'react';
import { Portal as TamaguiPortal } from 'tamagui';
import type { TamaguiElement, PortalProps, GetProps } from 'tamagui';

export const PORTAL_ROOT_REF = React.createRef<TamaguiElement>();
export const MinimumNativePortalZIndex = 100_000 + 1;

/**
 * Custom portal component that wraps the tamagui portal component.
 * Attaches itself to the root element div which lives in the UIProvider.
 * @param children - The children to render in the portal.
 */
export const Portal = ({ children, ref: _ref, ...rest }: PortalProps) => {
  const hostElement = PORTAL_ROOT_REF.current;

  const ref = _ref as GetProps<typeof TamaguiPortal>['ref'];

  return (
    <TamaguiPortal host={hostElement} {...rest} ref={ref}>
      {children}
    </TamaguiPortal>
  );
};

export type { PortalProps };

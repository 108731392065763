'use client';
import dayjs from 'dayjs';
// eslint-disable-next-line no-restricted-imports -- Disable this rule because we need to import the t function from i18next
import { t } from 'i18next';
import { z } from 'zod';

function ValidDDMMYYYFormat<T extends string>(
  key: T,
  val: Partial<Record<T, string>>,
  ctx: z.RefinementCtx,
  formats = ['DD/MM/YYYY']
) {
  const date = dayjs(val[key], formats, true);
  if (!date.isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t('fields.date.invalidDateFormatDDMMYYYY'),
      path: [key],
    });
  }
}

function ValidMMYYYYFormat<T extends string>(key: T, val: Partial<Record<T, string>>, ctx: z.RefinementCtx) {
  const date = dayjs(val[key], ['MM/YYYY'], true);
  if (!date.isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t('fields.date.invalidDateFormatMMYYYY'),
      path: [key],
    });
  }
}

function ValidYYYYFormat<T extends string>(key: T, val: Partial<Record<T, string>>, ctx: z.RefinementCtx) {
  const date = dayjs(val[key], ['YYYY'], true);
  if (!date.isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t('fields.date.invalidDateFormatYYYY'),
      path: [key],
    });
  }
}

function NoPastDate<T extends string>(key: T, val: Partial<Record<T, string>>, ctx: z.RefinementCtx) {
  const date = dayjs(val[key], ['DD/MM/YYYY', 'D/M/YYYY', 'MM/YYYY'], true);
  if (date.toDate() < new Date()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t('fields.date.noPast'),
      path: [key],
    });
  }
}

function NoFutureDate<T extends string>(key: T, val: Partial<Record<T, string>>, ctx: z.RefinementCtx) {
  const date = dayjs(val[key], ['DD/MM/YYYY', 'D/M/YYYY', 'MM/YYYY'], true);
  if (date.toDate() > new Date()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t('fields.date.noFuture'),
      path: [key],
    });
  }
}

function MinimumAge<T extends string>(key: T, val: Record<T, string>, ctx: z.RefinementCtx, age: number) {
  const date = dayjs(val[key], ['DD/MM/YYYY', 'D/M/YYYY'], true);
  if (date.toDate() > dayjs().subtract(age, 'year').toDate()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t('fields.date.mustBeYearsOld', { age }),
      path: [key],
    });
  }
}

function CannotBeBeforeDate<T extends string>(
  key: T,
  val: Partial<Record<T, string>>,
  ctx: z.RefinementCtx,
  startDate: string,
  message?: string
) {
  const date = dayjs(val[key], ['DD/MM/YYYY', 'D/M/YYYY', 'YYYY'], true);
  const start = dayjs(startDate, ['DD/MM/YYYY', 'D/M/YYYY', 'YYYY'], true);
  if (!date.isValid() || !start.isValid()) {
    return;
  }
  if (date < start) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: message ?? t('fields.date.cannotBeBefore', { date: start.format('DD/MM/YYYY') }),
      path: [key],
    });
  }
}

export const DateFieldValidators = {
  ValidDDMMYYYFormat,
  ValidMMYYYYFormat,
  ValidYYYYFormat,
  NoPastDate,
  NoFutureDate,
  MinimumAge,
  CannotBeBeforeDate,
};

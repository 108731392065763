import type { ReactNode } from 'react';
import { Check } from '@tamagui/lucide-icons';
import { useStringFieldInfo, useTsController } from '@ts-react/form';
import { Fieldset, XStack, YStack, Stack, Label } from 'tamagui';
import type { CheckboxProps } from 'tamagui';
import type { Animated } from 'react-native';
import type { GetThemeValueForKey } from '@tamagui/web';
import { Checkbox } from '../../Checkbox';
import { Alert } from '../../Alert';
import { H4 } from '../../Heading';

export interface MultiCheckboxRoleFieldProps extends Pick<CheckboxProps, 'testID' | 'disabled'> {
  outlined?: boolean;
  children?: ReactNode;
  options: { label: string; value: string; disabled?: boolean; description?: string }[];
  styles: {
    direction: 'row' | 'column';
    width: number | Animated.AnimatedNode | GetThemeValueForKey<'width'> | null | undefined;
  };
  /**
   * If an exclusive role is checked, the all other checkboxes will be unchecked.
   */
  exclusiveRoles?: string[];
  /**
   * An immutable role cannot be unchecked, even if the user selects an exclusive role.
   */
  immutableRoles?: string[];
}

export const MultiCheckboxRoleField = ({
  testID,
  disabled = false,
  outlined = true,
  children,
  options,
  styles,
  exclusiveRoles = [],
  immutableRoles = [],
}: MultiCheckboxRoleFieldProps) => {
  const { field, error, formState } = useTsController<string[]>();
  const isDisabled = formState.isSubmitting || disabled;
  const zodFieldInfo = useStringFieldInfo();
  const { label } = zodFieldInfo;
  /**
   * If a role specified in the `exclusiveRoles` array is selected and `isReadOnlyExclusive` is true,
   * all other checkboxes will be unchecked, and the selected role will be the only value in the currentValues array.
   * Otherwise, it adds or removes the selected value from the currentValues array.
   *
   */
  const handleCheckedChange = (itemValue: string, checkedState: boolean) => {
    let currentValues = [...new Set(field.value)];

    if (exclusiveRoles.includes(itemValue)) {
      if (checkedState) {
        // Keep any immutable roles that are already selected
        currentValues = [itemValue, ...immutableRoles.filter((val) => currentValues.includes(val))];
      } else {
        // Keep any immutable roles that are already selected
        currentValues = [...immutableRoles.filter((val) => currentValues.includes(val))];
      }
    } else if (checkedState) {
      currentValues.push(itemValue);
    } else {
      currentValues = currentValues.filter((value) => value !== itemValue);
    }

    field.onChange(currentValues);
  };

  return (
    <Fieldset>
      {Boolean(label) && (
        <H4 color="$foreground/surface-default" paddingBottom="$space.xl">
          {label}
        </H4>
      )}
      <Stack width="inherit" flexWrap="wrap" flexDirection={styles.direction}>
        {options.map((item, index, array) => {
          const checked = !!field.value?.includes(item.value);
          return (
            <XStack
              key={item.value}
              gap="$space.md"
              alignItems="center"
              padding={outlined ? '$space.lg' : null}
              marginBottom={outlined && index < array.length - 1 ? '$space.sm' : null}
              borderWidth={outlined ? '$space.2xs' : undefined}
              borderRadius="$radius.sm"
              borderColor={
                // eslint-disable-next-line no-nested-ternary -- ignore
                item.disabled
                  ? '$border/surface-subdued'
                  : outlined && checked
                    ? '$checkbox/color/checkbox-card-border-selected'
                    : '$border/surface-subdued'
              }
              backgroundColor={outlined && checked ? '$checkbox/color/checkbox-card-bg-selected' : undefined}
              overflow="hidden"
              width="100%"
              $tablet={{ width: styles.width }}
              paddingBottom="$lg"
              paddingRight="$xl"
            >
              <Checkbox
                ref={field.ref}
                id={item.value}
                testID={`${testID || item.label}-checkbox`}
                disabled={isDisabled || item.disabled}
                checked={checked}
                onCheckedChange={(checkedState) => {
                  handleCheckedChange(item.value, !!checkedState);
                }}
              >
                <Checkbox.Indicator>
                  <Check color={item.disabled ? '$foreground/disabled' : '$checkbox/color/checkbox-icon-selected'} />
                </Checkbox.Indicator>
              </Checkbox>
              {children ? (
                children
              ) : (
                <YStack>
                  <Label
                    testID={`${testID || item.label}-label`}
                    htmlFor={item.value}
                    cursor="pointer"
                    color={item.disabled ? '$text/disabled' : '$foreground/app-default'}
                    lineHeight={20}
                    fontSize="$md"
                    whiteSpace="pre-wrap"
                    fontWeight={item.description ? 'bold' : 'normal'}
                  >
                    {item.label}
                  </Label>
                  {item.description ? (
                    <Label
                      testID={`${testID || item.label}-description`}
                      htmlFor={item.value}
                      cursor="pointer"
                      color={item.disabled ? '$text/disabled' : '$foreground/app-default'}
                      lineHeight={20}
                      fontSize="$sm"
                      whiteSpace="pre-wrap"
                      marginTop="$space.xs"
                    >
                      {item.description}
                    </Label>
                  ) : null}
                </YStack>
              )}
            </XStack>
          );
        })}
      </Stack>
      {error?.errorMessage ? (
        <Stack marginTop="$space.lg">
          <Alert severity="danger" variant="inline-transparent">
            {error.errorMessage}
          </Alert>
        </Stack>
      ) : null}
    </Fieldset>
  );
};

'use client';

import { Search } from '@tamagui/lucide-icons';
import { useDescription, useTsController } from '@ts-react/form';
import { useEffect, useMemo, useState } from 'react';
import type { DefaultValues } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { Fieldset, XStack, YStack } from 'tamagui';
import { z } from 'zod';
import { Button } from '../../../../Button';
import { H3 } from '../../../../Heading';
import { Input } from '../../../../Input';
import { Select } from '../../../../Select';
import type { TypeaheadFieldProps, UseTypeaheadFieldHookReturn } from '../TypeaheadField';
import { TypeaheadField } from '../TypeaheadField';

export const STATES = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

const AUS_POSTCODE_LENGTH = 4;

export const getManualFullAddress = ({
  unitNumber,
  streetNumber,
  streetName,
  suburb,
  state,
  postcode,
}: Partial<AddressFieldItem>): string =>
  `${unitNumber?.trim() ?? ''} ${streetNumber?.trim() ?? ''} ${streetName?.trim() ?? ''}, ${suburb?.trim() ?? ''} ${
    state?.trim() ?? ''
  } ${postcode?.trim() ?? ''}`
    .replace(/ +(?= )/g, '') // Remove extra spaces
    .toUpperCase();

export const createAddressFieldItemSchema = (params?: z.RawCreateParams) =>
  z.object(
    {
      fullAddress: z.string(),
      unitNumber: z.string().optional(),
      streetNumber: z.string({ required_error: 'Please enter a street number' }).min(1, 'Please enter a street number'),
      streetName: z
        .string({
          required_error: 'Please enter a street name',
        })
        .min(1, 'Please enter a street name'),
      suburb: z
        .string({
          required_error: 'Please enter a suburb',
        })
        .min(1, 'Please enter a suburb'),
      state: z
        .string({
          required_error: 'Please select a state or territory',
        })
        .min(1, 'Please select a state or territory'),
      postcode: z
        .string({
          required_error: 'Please enter a postcode',
        })
        .min(1, 'Please enter a postcode'),
      country: z.string().optional(), // Search form only
      streetType: z.string().optional(), // Search form only
      type: z.string(),
      dpid: z.string().optional(), // Search form only
      searchId: z.string().optional(), // Search form only
    },
    params ?? {
      required_error: 'Please enter an address',
    }
  );

export type AddressFieldSchema = ReturnType<typeof createAddressFieldItemSchema>;
export type AddressFieldItem = z.infer<AddressFieldSchema>;
export type AddressFieldProps = TypeaheadFieldProps<AddressFieldItem> & {
  addressType: string;
  onCannotFindAddress?: () => void;
};

export function AddressField({
  extraItems,
  addressType,
  onCannotFindAddress,
  initialSearchValue,
  ...props
}: AddressFieldProps): JSX.Element {
  const { field, error, formState } = useTsController<AddressFieldItem>();
  const { label } = useDescription();
  const form = useFormContext();

  const [mode, setMode] = useState<'search' | 'manual'>('search');

  const defaultValue =
    (formState.defaultValues?.[field.name] as DefaultValues<AddressFieldItem> | undefined)?.fullAddress ?? '';

  const initialValue = initialSearchValue ?? defaultValue;
  const [search, setSearch] = useState<string>(initialValue);

  useEffect(() => {
    setSearch(initialValue);
  }, [initialValue, setSearch]);

  // Add manual address item to extraItems
  const extraItemsWithManualAddressItem = useMemo<
    NonNullable<UseTypeaheadFieldHookReturn<AddressFieldItem>['extraItems']>
  >(
    () => [
      ...(extraItems ?? []),
      {
        label: `Can't find your address?`,
        onPress: () => {
          if (onCannotFindAddress) {
            onCannotFindAddress();
          } else {
            setMode('manual');
            // Set to an object, so the validation runs on the properties
            form.setValue(field.name, {
              type: addressType,
            });
          }
        },
      },
    ],
    [extraItems, field.name, form, addressType, onCannotFindAddress]
  );

  return mode === 'search' ? (
    <Fieldset>
      <TypeaheadField<AddressFieldItem>
        search={search}
        setSearch={setSearch}
        // Manual address extra item only appears when there is a search value
        extraItems={search !== '' ? extraItemsWithManualAddressItem : extraItems}
        {...props}
      />
    </Fieldset>
  ) : (
    // Defining each field explicitly. Cannot use SchemaForm as it hasn't been defined yet.
    <YStack gap="$lg">
      <YStack gap="$space.2xl">
        {label ? <H3>{label}</H3> : null}
        <XStack
          gap="$space.2xl"
          flexDirection="column"
          $tablet={{
            gap: '$space.lg',
            flexDirection: 'row',
          }}
        >
          <Fieldset
            $tablet={{
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: '50%',
            }}
          >
            <Input
              testID={`${field.name}-unitNumber-input`}
              size="$lg"
              disabled={props.disabled}
              inputMode="text"
              onChangeText={(text) => {
                const fieldValue = {
                  ...field.value,
                  unitNumber: text,
                };
                field.onChange({
                  ...fieldValue,
                  fullAddress: getManualFullAddress(fieldValue),
                });
              }}
              label="Unit number"
              ref={field.ref}
              placeholder="Optional"
              value={field.value?.unitNumber ?? ''}
              error={error?.unitNumber?.errorMessage}
            />
          </Fieldset>
          <Fieldset
            $tablet={{
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: '50%',
            }}
          >
            <Input
              testID={`${field.name}-streetNumber-input`}
              size="$lg"
              disabled={props.disabled}
              inputMode="text"
              onChangeText={(text) => {
                const fieldValue = {
                  ...field.value,
                  streetNumber: text,
                };
                field.onChange({
                  ...fieldValue,
                  fullAddress: getManualFullAddress(fieldValue),
                });
              }}
              label="Street number"
              value={field.value?.streetNumber ?? ''}
              error={error?.streetNumber?.errorMessage}
            />
          </Fieldset>
        </XStack>
        <Fieldset>
          <Input
            testID={`${field.name}-streetName-input`}
            size="$lg"
            disabled={props.disabled}
            inputMode="text"
            onChangeText={(text) => {
              const fieldValue = {
                ...field.value,
                streetName: text,
              };
              field.onChange({
                ...fieldValue,
                fullAddress: getManualFullAddress(fieldValue),
              });
            }}
            label="Street name"
            value={field.value?.streetName ?? ''}
            error={error?.streetName?.errorMessage}
          />
        </Fieldset>
        <Fieldset>
          <Input
            testID={`${field.name}-suburb-input`}
            size="$lg"
            disabled={props.disabled}
            inputMode="text"
            onChangeText={(text) => {
              const fieldValue = {
                ...field.value,
                suburb: text,
              };
              field.onChange({
                ...fieldValue,
                fullAddress: getManualFullAddress(fieldValue),
              });
            }}
            label="Suburb"
            value={field.value?.suburb ?? ''}
            error={error?.suburb?.errorMessage}
          />
        </Fieldset>
        <Fieldset>
          <Select
            testID={`${field.name}-state-select`}
            size="$lg"
            disabled={props.disabled}
            label="State / Territory"
            placeholder="Select State / Territory"
            value={field.value?.state}
            error={error?.state?.errorMessage}
            items={STATES}
            itemToValue={(item) => item ?? ''}
            itemToLabel={(item) => item ?? ''}
            onValueChange={(value) => {
              const fieldValue = {
                ...field.value,
                state: value,
              };
              field.onChange({
                ...fieldValue,
                fullAddress: getManualFullAddress(fieldValue),
              });
            }}
          />
        </Fieldset>
        <Fieldset>
          <Input
            testID={`${field.name}-postcode-input`}
            size="$lg"
            disabled={props.disabled}
            inputMode="numeric"
            maxLength={AUS_POSTCODE_LENGTH}
            onChangeText={(text) => {
              const value = parseInt(text, 10);
              if (text !== '' && isNaN(value)) {
                return;
              }
              const fieldValue = {
                ...field.value,
                postcode: text === '' ? '' : value.toString(),
              };
              field.onChange({
                ...fieldValue,
                fullAddress: getManualFullAddress(fieldValue),
              });
            }}
            label="Postcode"
            value={field.value?.postcode ?? ''}
            error={error?.postcode?.errorMessage}
          />
        </Fieldset>
        <YStack alignItems="flex-start">
          <Button
            startIcon={<Search />}
            disabled={props.disabled}
            variant="no-outline"
            mode="primary"
            size="sm"
            onPress={() => {
              setMode('search');
              // Set back to undefined, so the validation runs on the entire object
              form.setValue(field.name, undefined);
            }}
          >
            Search address instead
          </Button>
        </YStack>
      </YStack>
    </YStack>
  );
}

import type { DatePickerProviderProps } from '@rehookify/datepicker';
import { DatePickerProvider as _DatePickerProvider } from '@rehookify/datepicker';
import { Adapt, Popover, styled, createStyledContext, withStaticProperties } from 'tamagui';
import { Elevation } from '../Elevation';
import type { DatePart, DatePickerImplProps } from './types';

const DatePickerProvider = _DatePickerProvider as React.ComponentType<DatePickerProviderProps>;
export const { Provider: HeaderTypeProvider, useStyledContext: useHeaderType } = createStyledContext<{
  type: DatePart;
  setHeader: (_datePart: DatePart) => void;
}>({
  type: 'day',
  setHeader: (_datePart: DatePart) => {
    // noop
  },
});

const DatePickerImpl = (props: DatePickerImplProps) => {
  const { children, config, shouldAdapt, ...rest } = props;

  return (
    <DatePickerProvider config={config}>
      <Popover {...rest}>
        {shouldAdapt ? (
          <Adapt when="mobile" platform="touch">
            <Popover.Sheet modal dismissOnSnapToBottom snapPointsMode="fit">
              <Popover.Sheet.Frame backgroundColor="$background/surface">
                <DatePickerProvider config={config}>
                  <Adapt.Contents />
                </DatePickerProvider>
              </Popover.Sheet.Frame>
              <Popover.Sheet.Overlay
                backgroundColor="$other/black-alpha-50"
                animation="quick"
                enterStyle={{ opacity: 0 }}
                exitStyle={{ opacity: 0 }}
              />
            </Popover.Sheet>
          </Adapt>
        ) : null}

        {children}
      </Popover>
    </DatePickerProvider>
  );
};

const Trigger = Popover.Trigger;
const Content = styled(Popover.Content, {
  name: 'DatePickerContent',

  unstyled: true,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$background/transparent',
  gap: '$space.xs',
  maxWidth: '100%',

  animation: [
    'quick',
    {
      opacity: {
        overshootClamping: true,
      },
    },
  ],
  enterStyle: { y: 10, opacity: 0 },
  exitStyle: { y: 10, opacity: 0 },
});

const Arrow = Popover.Arrow.styleable((props, ref) => {
  // Hack to make the arrow work with elevation
  return (
    <Popover.Arrow unstyled {...props} ref={ref} height="$size.xs" width="$size.xs">
      <Elevation level={4} shadow="hard" width="100%" height="100%" backgroundColor="$background/surface" />
    </Popover.Arrow>
  );
});

export const DatePickerBase = withStaticProperties(DatePickerImpl, {
  Trigger,
  Content: withStaticProperties(Content, {
    Arrow,
  }),
});

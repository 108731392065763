import { useDatePickerContext } from '@rehookify/datepicker';
import { View } from 'tamagui';
import { Text } from '../../Text';
import { swapOnClick } from '../helpers';

export const MonthPicker = ({ onChange }: { onChange?: (evt: MouseEvent, date: Date) => void }) => {
  const {
    data: { months },
    propGetters: { monthButton },
  } = useDatePickerContext();

  return (
    <View flexDirection="row" rowGap="$space.xl" flexWrap="wrap" justifyContent="space-evenly">
      {months.map((month) => (
        <View
          key={month.$date.toString()}
          themeInverse={month.active}
          hoverStyle={{ backgroundColor: '$foreground/surface-inverse-subdued' }}
          backgroundColor={month.active ? '$foreground/surface-inverse' : '$background/transparent'}
          minWidth="30%"
          minHeight="$size.md"
          justifyContent="center"
          alignItems="center"
          borderRadius="$radius.3xl"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Suppressing error for implicit mouseEvent
          {...swapOnClick(monthButton(month, { onClick: onChange as any }))}
          tabIndex={0}
        >
          <Text variant="bodySmall" textAlign="center">
            {month.month}
          </Text>
        </View>
      ))}
    </View>
  );
};

import { YStack } from 'tamagui';
import type { ReactNode } from 'react';
import type { GetThemeValueForKey } from '@tamagui/web';
import { PageFrameProvider } from './PageFrameContext';
import type { KeyboardWrapperProps } from './KeyboardWrapper';

export interface PageFrameBaseProps {
  children: ReactNode;
  backgroundColor?: GetThemeValueForKey<'backgroundColor'>;
  padding?: '$lg' | '$xl';
  /**
   * Whether the footer should stick above the keyboard. Default is true.
   *
   * Only works in native.
   */
  stickyFooter?: boolean; // Only used in native
  /**
   * Additional vertical offset of the footer above the keyboard. Default is 0.
   *
   * Only works in native.
   */
  keyboardVerticalOffset?: KeyboardWrapperProps['keyboardVerticalOffset'];
}

export const PageFrameBase = ({
  children,
  padding = '$lg',
  backgroundColor = '$background/surface',
}: PageFrameBaseProps) => {
  return (
    <PageFrameProvider backgroundColor={backgroundColor}>
      <YStack
        paddingVertical={padding}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        flex={1}
        backgroundColor={backgroundColor}
      >
        {children}
      </YStack>
    </PageFrameProvider>
  );
};

'use client';

import type { ReactElement } from 'react';
import type { PortalProps, YStackProps } from 'tamagui';
import { isWeb, Sheet as TamaguiSheet, useWindowDimensions, YStack } from 'tamagui';
import { Portal } from '../Portal';
import { useSafeAreaInsets } from '../../hooks';
import { AnimatePresence } from '../AnimatePresence';

const getRatioFromPercentage = (num: string): number => {
  return parseInt(num.replace('%', ''), 10) / 100;
};

export interface BaseBottomSheetProps {
  /**
   * Components to render in the sheet
   */
  children: ReactElement | ReactElement[] | null;
  /**
   * Controlled state of whether the sheet is open
   */
  open: boolean;
  /**
   * Called when the sheet open state changes
   */
  onOpenChange: (open: boolean) => void;
  /**
   * Props to pass to the content container of the sheet
   */
  contentContainerProps?: YStackProps;
  /*
   * The height of the sheet. Default is 100%.
   */
  height?: '30%' | '40%' | '50%' | '60%' | '70%' | '80%' | '90%' | '100%';
  /**
   * Whether the sheet is dismissible by clicking outside of it. Default is true.
   */
  dismissible?: boolean;
  /**
   * Portal props
   */
  portalProps?: PortalProps;
}

export const BaseBottomSheet = ({
  open,
  onOpenChange,
  contentContainerProps,
  height = '100%',
  dismissible = true,
  children,
  portalProps,
}: BaseBottomSheetProps) => {
  const { height: windowHeight } = useWindowDimensions();
  const insets = useSafeAreaInsets();
  const heightRatio = getRatioFromPercentage(height);
  return (
    <Portal {...(isWeb && open ? { pointerEvents: 'auto' } : {})} {...portalProps}>
      <TamaguiSheet
        unmountChildrenWhenHidden
        open={open}
        onOpenChange={onOpenChange}
        modal={false}
        snapPointsMode="fit"
        dismissOnSnapToBottom={dismissible}
        dismissOnOverlayPress={dismissible}
      >
        <TamaguiSheet.Overlay
          backgroundColor="$other/black-alpha-50"
          key="overlay"
          animation="sheet"
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
        />
        <TamaguiSheet.Frame
          height={isWeb ? `${100 * heightRatio}vh` : (windowHeight - insets.top - insets.bottom) * heightRatio}
          justifyContent="center"
          alignItems="center"
          animation="250ms"
          width="100%"
          // FIXME: SafeAreaView doesn't work with Portals (it does work without though)
          marginTop={insets.top}
          marginBottom={insets.bottom}
          backgroundColor="$background/surface"
          {...contentContainerProps}
        >
          <AnimatePresence>
            {open ? (
              <YStack width="100%" flex={1}>
                {children}
              </YStack>
            ) : null}
          </AnimatePresence>
        </TamaguiSheet.Frame>
      </TamaguiSheet>
    </Portal>
  );
};

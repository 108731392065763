'use client';

import { getTokenValue, XStack } from 'tamagui';
import { useState } from 'react';
import type { ButtonProps } from '../../Button';
import { Button } from '../../Button';

// Give developers control over when to render buttons
export interface PageFrameFooterButtonsProps {
  testID?: string;
  primaryButtonText?: string;
  primaryButtonOnPress?: () => void;
  secondaryButtonText?: string;
  secondaryButtonOnPress?: () => void;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
}

// TODO: Fix button wrapping
export const PageFrameFooterButtons = ({
  testID,
  primaryButtonOnPress,
  primaryButtonText,
  primaryButtonProps,
  secondaryButtonText,
  secondaryButtonOnPress,
  secondaryButtonProps,
}: PageFrameFooterButtonsProps) => {
  const [containerWidth, setContainerWidth] = useState(0);
  // Hack around button wrapping if the content is too long. We still want ellipsis on the longer button layout.
  const maxButtonContentWidth = (containerWidth - getTokenValue('$sm') - getTokenValue('$2xl') * 2) / 2;

  return (
    <XStack
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      gap="$sm"
      flexWrap="wrap"
      onLayout={(e) => {
        setContainerWidth(e.nativeEvent.layout.width);
      }}
    >
      {primaryButtonText ? (
        <XStack
          flexBasis={primaryButtonText.length * 16 > maxButtonContentWidth ? containerWidth : 0}
          flexGrow={1}
          flexShrink={1}
        >
          <Button
            flex={1}
            variant="filled"
            mode="primary"
            size="md"
            focusable={false}
            testID={`${testID || 'page-frame'}-button-primary}`}
            onPress={primaryButtonOnPress}
            textProps={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            {...primaryButtonProps}
          >
            {primaryButtonText}
          </Button>
        </XStack>
      ) : null}
      {secondaryButtonText ? (
        <XStack
          flexBasis={secondaryButtonText.length * 16 > maxButtonContentWidth ? containerWidth : 0}
          flexGrow={1}
          flexShrink={1}
        >
          <Button
            flex={1}
            variant="outlined"
            mode="primary"
            size="md"
            focusable={false}
            testID={`${testID || 'page-frame'}-button-secondary}`}
            onPress={secondaryButtonOnPress}
            textProps={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            {...secondaryButtonProps}
          >
            {secondaryButtonText}
          </Button>
        </XStack>
      ) : null}
    </XStack>
  );
};

import type { GetProps } from '@tamagui/web';
import { withStaticProperties } from '@tamagui/web';
import { PageFrameBase } from './components/PageFrameBase';
import { PageFrameHeader } from './components/PageFrameHeader';
import { PageFrameContent } from './components/PageFrameContent';
import { PageFrameFooter } from './components/PageFrameFooter';
import { PageFrameHeaderTitleBar } from './components/PageFrameHeaderTitleBar';
import { PageFrameIconButton } from './components/PageFrameIconButton';
import { PageFrameFooterButtons } from './components/PageFrameFooterButtons';

export const PageFrame = withStaticProperties(PageFrameBase, {
  Header: PageFrameHeader,
  HeaderTitleBar: PageFrameHeaderTitleBar,
  Content: PageFrameContent,
  IconButton: PageFrameIconButton,
  Footer: PageFrameFooter,
  FooterButtons: PageFrameFooterButtons,
});

export type PageFrameProps = GetProps<typeof PageFrame>;

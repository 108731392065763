import type { DPDay } from '@rehookify/datepicker';
import { useDatePickerContext } from '@rehookify/datepicker';
import { useMemo } from 'react';
import { AnimatePresence, Button, View } from 'tamagui';
import { match } from 'ts-pattern';
import type { GetThemeValueForKey } from '@tamagui/web';
import { Text } from '../../Text';
import { swapOnClick } from '../helpers';
import { useDateAnimation } from './useDateAnimation';

export const DayPicker = function DayPicker({ minDate }: { minDate?: Date }) {
  const {
    data: { calendars, weekDays },
    propGetters: { dayButton },
  } = useDatePickerContext();
  const { prevNextAnimation, prevNextAnimationKey } = useDateAnimation({
    listenTo: 'month',
  });

  const [calendar] = calendars;

  const subDays = useMemo(() => {
    const calenderDays = calendar?.days;

    if (!calenderDays?.length) {
      return [];
    }

    return calenderDays.reduce<DPDay[][]>((acc, day, i) => {
      if (i % 7 === 0) {
        acc.push([day]);
        return acc;
      }

      acc[acc.length - 1]?.push(day);
      return acc;
    }, []);
  }, [calendar?.days]);

  if (!calendar) {
    return null;
  }

  return (
    <AnimatePresence key={prevNextAnimationKey}>
      <View flexDirection="column" gap="$space.lg" animation="quick" {...prevNextAnimation()}>
        <View flexDirection="row" justifyContent="space-between">
          {weekDays.map((dayName) => (
            <Button // Hack for calender grid
              key={dayName}
              circular
              backgroundColor="$background/transparent"
              justifyContent="center"
              alignItems="center"
              size="$lg"
            >
              <Text variant="bodyMediumEm" textAlign="center">
                {dayName.slice(0, 2) /* 2 Chars in date */}
              </Text>
            </Button>
          ))}
        </View>
        <View gap="$space.lg">
          {subDays.map((days, week) => {
            return (
              <View flexDirection="row" key={`week-${week}`} justifyContent="space-between">
                {days.map((day) => {
                  const newMinDate = minDate ? new Date(minDate) : undefined;
                  if (newMinDate) {
                    newMinDate.setDate(newMinDate.getDate() - 1);
                  }
                  const isDayDisabled = newMinDate && newMinDate.getTime() >= day.$date.getTime();
                  const isDayOutOfMonth = day.inCurrentMonth;
                  return (
                    <Button // Hack for calender grid
                      key={day.$date.toString()}
                      circular
                      justifyContent="center"
                      alignItems="center"
                      themeInverse={day.selected}
                      size="$lg"
                      hoverStyle={{ backgroundColor: '$foreground/surface-inverse-subdued' }}
                      backgroundColor={day.selected ? '$foreground/surface-inverse' : '$background/transparent'}
                      disabled={!day.inCurrentMonth || isDayDisabled}
                      {...swapOnClick(dayButton(day))}
                      tabIndex={0}
                    >
                      <Text
                        variant="bodyMedium"
                        textAlign="center"
                        color={match({ isDayOutOfMonth, isDayDisabled })
                          .with(
                            { isDayOutOfMonth: false },
                            () => '$background/transparent' as GetThemeValueForKey<'color'>
                          )
                          .with(
                            { isDayDisabled: true },
                            () => '$foreground/surface-subdued' as GetThemeValueForKey<'color'>
                          )

                          .otherwise(() => '$foreground/surface-default' as GetThemeValueForKey<'color'>)}
                      >
                        {day.day}
                      </Text>
                    </Button>
                  );
                })}
              </View>
            );
          })}
        </View>
      </View>
    </AnimatePresence>
  );
};

import type { StackProps } from 'tamagui';
import { getTokenValue, Stack } from 'tamagui';
import type { ReactElement } from 'react';
import type { GestureResponderEvent } from 'react-native';

const iconMarginInset = (getTokenValue('$icon-button/size/lg') - getTokenValue('$icon/size/xs')) / 2;
export const PageFrameIconButton = ({
  icon,
  onPress,
  ...stackProps
}: {
  icon: ReactElement;
  onPress?: (event: GestureResponderEvent) => void;
} & StackProps) => {
  return (
    <Stack
      width="$icon-button/size/lg"
      height="$icon-button/size/lg"
      display="flex"
      justifyContent="center"
      alignItems="center"
      onPress={onPress}
      cursor="pointer"
      marginHorizontal={-iconMarginInset}
      marginVertical={-iconMarginInset}
      {...stackProps}
    >
      {icon}
    </Stack>
  );
};

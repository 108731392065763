'use client';

import type { AccordionTriggerProps, YStackProps } from 'tamagui';
import { Accordion, Nav, Square, XStack, YStack, getTokens, withStaticProperties } from 'tamagui';
import type { ReactElement } from 'react';
import React, {
  Children,
  createContext,
  isValidElement,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import type { IconProps } from '@tamagui/helpers-icon';
import { Check, ChevronDown, Dot } from '@tamagui/lucide-icons';
import { Text } from '../Text';
import { ScrollView } from '../ScrollView';
import { NumberIcon } from './NumberIcon';

/* -------------------------------------------------------------------------------------------------
 * Contexts
 * -----------------------------------------------------------------------------------------------*/

/**
 * Shared values between StepNavigationGroup and StepNavigationItem
 */
interface StepNavigationContextValues {
  selected: string;
  onItemSelected: (id: string) => void;
  open: string[];
  setOpen: React.Dispatch<React.SetStateAction<string[]>>;
  setAllOpen: () => void;
  /**
   * Ids of previous steps + currently selected step id
   */
  previousSteps: string[];
  mode: 'full' | 'compact';
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

const StepNavigationContext = createContext<StepNavigationContextValues | undefined>(undefined);

const useNavigationContext = () => {
  const context = useContext(StepNavigationContext);
  if (!context) {
    throw new Error('useNavigationContext must be used within a StepNavigationBase');
  }
  return context;
};

/**
 * Shared values for all StepNavigationItems within a StepNavigationGroup only
 */
interface StepNavigationGroupContextValues {
  groupId: string;
  groupName: string;
  isSiblingSelected: boolean;
}

const StepNavigationGroupContext = createContext<StepNavigationGroupContextValues | undefined>(undefined);

const useGroupContext = () => {
  const context = useContext(StepNavigationGroupContext);
  return context;
};

/**
 * Shared values for all children of StepNavigation, i.e. the main steps
 */
interface StepNavigationStepContextValues {
  stepNumber: number;
}

const StepNavigationStepContext = createContext<StepNavigationStepContextValues | undefined>(undefined);

const useStepContext = () => {
  const context = useContext(StepNavigationStepContext);
  if (!context) {
    throw new Error('useStepContext must be used within a StepNavigationBase');
  }
  return context;
};

/* -----------------------------------------------------------------------------------------------*/

/* -------------------------------------------------------------------------------------------------
 * StepNavigation components
 * -----------------------------------------------------------------------------------------------*/

export interface StepNavigationBaseChildProps {
  /**
   * Unique id for the item
   */
  id: string;
  /**
   * Label to display
   */
  label: string;
  /**
   * If the item is disabled
   */
  disabled?: boolean;
  onPress?: () => void;
  testID?: string;
}

export interface StepNavigationProps {
  /**
   * The currently selected `StepNavigationGroup` or `StepNavigationItem`.
   *
   * Only use if you want StepNavigation to be controlled.
   */
  selected?: string;
  /**
   * Callback when a `StepNavigationItem` is pressed.
   *
   * `selected` must also be passed if `onItemSelected` is passed.
   *
   * This will be triggered before any `onPress` callbacks from `StepNavigationItem` or `StepNavigationGroup`
   *
   * Only use if you want `StepNavigation` to be controlled.
   *
   */
  onItemSelected?: (id: string) => void;
  /**
   * The id of default selected item.
   *
   * Only use if you want StepNavigation to be uncontrolled.
   */
  defaultSelected?: string;
  /**
   * The default open navigation groups. Defaults to 'all'
   */
  defaultOpen?: string[] | 'all';
  /**
   * `StepNavigationGroup` and `StepNavigationItem` components
   */
  children?: React.ReactNode;
  /**
   * The render mode. Defaults to 'full'
   */
  mode?: 'full' | 'compact';
  /**
   * The current collapsed state of the navigation, only in compact mode.
   *
   * Only use if you want to control this state
   */
  collapsed?: boolean;
  /**
   * Callback when the collapsed state changes, only in compact mode.
   *
   * Only use if you want to control this state
   */
  setCollapsed?: (collapsed: boolean) => void;
  /**
   * Test ID
   */
  testID?: string;
}

export function StepNavigationBase({
  selected: controlledSelected,
  onItemSelected,
  defaultSelected = '',
  collapsed: controlledCollapsed,
  setCollapsed: controlledSetCollapsed,
  defaultOpen = 'all',
  children,
  mode = 'full',
  testID,
}: StepNavigationProps) {
  const childGroupIds: string[] = useMemo(
    () =>
      Children.toArray(children)
        .filter((child) => isValidElement<StepNavigationBaseChildProps>(child) && child.type === StepNavigationGroup)
        .map((child) => (child as ReactElement<StepNavigationBaseChildProps>).props.id),
    [children]
  );

  // Flatten steps into an ordered array of ids
  const orderedStepsById: (string | Record<string, string[]>)[] = useMemo(() => {
    const validChildren: (ReactElement<StepNavigationGroupProps> | ReactElement<StepNavigationItemProps>)[] =
      Children.toArray(children).filter(
        (child) =>
          isValidElement<StepNavigationBaseChildProps>(child) &&
          (child.type === StepNavigationGroup || child.type === StepNavigationItem)
      ) as (ReactElement<StepNavigationGroupProps> | ReactElement<StepNavigationItemProps>)[];

    const steps: (string | Record<string, string[]>)[] = [];

    validChildren.forEach((child) => {
      if (child.type === StepNavigationGroup) {
        steps.push({
          [child.props.id]: Children.toArray((child.props as StepNavigationGroupProps).children).map(
            (item) => (item as ReactElement<StepNavigationItemProps>).props.id
          ),
        });
      } else {
        steps.push(child.props.id);
      }
    });

    return steps;
  }, [children]);

  // Default opened accordion item
  // Combined between defaultOpen + what should be open based on defaultSelected / controlledSelected
  const getDefaultOpenValue = useCallback(() => {
    const getDefaultOpenFromSelected = () => {
      const currentDefaultSelected = controlledSelected ?? defaultSelected;
      if (!currentDefaultSelected) {
        return [];
      }
      for (const step of orderedStepsById) {
        // Step is a StepNavigationItem
        // If defaultSelected is a top-level StepNavigationItem, then it doesn't need to be the Accordion's defaultValue
        if (typeof step === 'string') {
          if (step === currentDefaultSelected) {
            return [];
          }
        }
        // Step is a StepNavigationGroup
        else {
          const group = Object.entries(step)[0];
          if (!group) {
            return [];
          }
          const [groupId, groupSteps] = group;
          // If defaultSelected is a StepNavigationGroup, then it's the Accordion's defaultValue
          if (groupId === currentDefaultSelected) {
            return [groupId];
          }
          // If default is a child item of a StepNavigationGroup, then this StepNavigationGroup needs to be the Accordion's defaultValue
          if (groupSteps.includes(currentDefaultSelected)) {
            return [groupId];
          }
        }
      }
      return [];
    };
    return [...new Set([...defaultOpen, ...getDefaultOpenFromSelected()])];
  }, [controlledSelected, defaultOpen, defaultSelected, orderedStepsById]);

  // The currently selected StepNavigationGroup or StepNavigationItem id.
  const [selected, setSelected] = useState<string>(defaultSelected);
  // The currently open StepNavigationGroup id
  const [open, setOpen] = useState<string[]>(defaultOpen === 'all' ? childGroupIds : getDefaultOpenValue());
  // Whether the navigation is collapsed in compact mode or not
  const [collapsedUncontrolled, setCollapsedUncontrolled] = useState<boolean>(true);

  const collapsed = useMemo(
    () => controlledCollapsed ?? collapsedUncontrolled,
    [collapsedUncontrolled, controlledCollapsed]
  );
  const setCollapsed = useMemo(() => controlledSetCollapsed ?? setCollapsedUncontrolled, [controlledSetCollapsed]);

  const contextValue = useMemo<StepNavigationContextValues>(() => {
    const selectedValue = controlledSelected ?? selected;
    // Get list of steps/sub-steps before the currently selected item + currently selected item
    const previousSteps: string[] = [];
    // If nothing is selected yet, then previousSteps should be empty
    let selectedItemFound = selectedValue === '';
    orderedStepsById.forEach((step, index) => {
      if (selectedItemFound) {
        return;
      }
      if (typeof step === 'string') {
        if (step === selectedValue) {
          selectedItemFound = true;
        }
        if (index === 0) {
          return;
        }
        previousSteps.push(step);
      } else {
        // Should be only one entry
        Object.entries(step).forEach(([groupId, substeps]) => {
          if (index !== 0) {
            previousSteps.push(groupId);
          }
          substeps.forEach((substep) => {
            if (selectedItemFound) {
              return;
            }
            previousSteps.push(substep);
            if (substep === selectedValue) {
              selectedItemFound = true;
            }
          });
        });
      }
    });

    return {
      selected: selectedValue,
      onItemSelected: onItemSelected ?? setSelected,
      open,
      setOpen,
      setAllOpen: () => {
        setOpen(childGroupIds);
      },
      previousSteps,
      mode,
      collapsed,
      setCollapsed,
    };
  }, [
    childGroupIds,
    collapsed,
    controlledSelected,
    mode,
    onItemSelected,
    open,
    orderedStepsById,
    selected,
    setCollapsed,
  ]);

  return (
    <StepNavigationContext.Provider value={contextValue}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        backgroundColor="$step-navigation/color/step-bg-default"
        contentContainerStyle={{
          paddingTop: 2, // For focus style of first item to not be cut off
        }}
      >
        <Nav
          backgroundColor="$step-navigation/color/step-bg-default"
          minHeight={mode === 'compact' && !collapsed ? '100dvh' : '100%'}
          testID={testID}
        >
          <Accordion
            type="multiple" // can have multiple groups open at a time (though still only one selected)
            value={open}
          >
            {/* Validate that all children are either StepNavigationGroup or StepNavigationItem components */}
            {Children.map(children, (child, index) => {
              if (
                isValidElement<StepNavigationBaseChildProps>(child) &&
                (child.type === StepNavigationGroup || child.type === StepNavigationItem)
              ) {
                // If StepNavigationItem, need to wrap with Accordion.Item to be considered as a item within Accordion.
                if (child.type === StepNavigationItem) {
                  return (
                    <Accordion.Item value={child.props.id}>
                      <Accordion.Header unstyled>
                        <StepNavigationStepContext.Provider
                          value={{
                            stepNumber: index + 1,
                          }}
                        >
                          {child}
                        </StepNavigationStepContext.Provider>
                      </Accordion.Header>
                    </Accordion.Item>
                  );
                }

                // StepNavigationGroup
                return (
                  <StepNavigationStepContext.Provider
                    value={{
                      stepNumber: index + 1,
                    }}
                  >
                    {child}
                  </StepNavigationStepContext.Provider>
                );
              }

              throw new Error(
                'Invalid children. Expected children to consist of StepNavigationGroup or StepNavigationItem components.'
              );
            })}
          </Accordion>
        </Nav>
      </ScrollView>
    </StepNavigationContext.Provider>
  );
}

export interface StepNavigationGroupProps extends StepNavigationBaseChildProps {
  children: React.ReactNode;
}

/**
 * A component to render a group of sub-steps
 *
 * Assumptions
 *
 * - All children must be StepNavigationItem components
 * - If all children are either completed or locked, the StepNavigationGroup is also considered complete
 * - StepNavigationGroup can be disabled, which would disable the ability to open/close the group. Nothing will occur if all children are disabled,
 * - index will be set based on the order of the children
 */
function StepNavigationGroup({ id, label, disabled = false, onPress, children, testID }: StepNavigationGroupProps) {
  const navContext = useNavigationContext();
  const stepContext = useStepContext();

  const { selected, setOpen, previousSteps, mode, collapsed, setCollapsed, setAllOpen } = navContext;

  const childItems: StepNavigationItemProps[] = useMemo(
    () =>
      Children.toArray(children)
        .filter((child) => isValidElement<StepNavigationItemProps>(child))
        .map((child) => (child as ReactElement<StepNavigationItemProps>).props),
    [children]
  );

  const selectedChild = childItems.find((child) => child.id === selected);
  const allChildrenComplete = childItems.every((props) => props.completed);

  const isChildSelected = selectedChild !== undefined;

  // If controlled by user, then will need to automatically open group if child selected (as this functionality isn't exposed)
  useEffect(() => {
    if (isChildSelected) {
      setOpen((prevOpen) => {
        if (!prevOpen.includes(id)) {
          return [...prevOpen, id];
        }
        return prevOpen;
      });
    }
  }, [id, isChildSelected, setOpen]);

  // Auto close current group when all children are complete for the first time
  //(if the user later opens and closes group, it will not close again)
  useEffect(() => {
    if (allChildrenComplete) {
      setOpen((prevOpen) => {
        return prevOpen.filter((item) => item !== id);
      });
    }
  }, [allChildrenComplete, setOpen, id]);

  let icon: ReactElement<IconProps> = <NumberIcon variant="incomplete">{`${stepContext.stepNumber}`}</NumberIcon>;
  if (allChildrenComplete) {
    icon = <NumberIcon testID={`${label}-completed-icon`} variant="complete">{`${stepContext.stepNumber}`}</NumberIcon>;
  }
  if (isChildSelected) {
    icon = <NumberIcon testID={`${label}-selected-icon`} variant="active">{`${stepContext.stepNumber}`}</NumberIcon>;
  }

  return mode === 'full' || !collapsed || isChildSelected ? (
    <Accordion.Item
      value={id}
      backgroundColor={
        isChildSelected ? '$step-navigation/color/step-bg-selected' : '$step-navigation/color/step-bg-default'
      }
      pointerEvents={disabled ? 'none' : 'auto'}
      disabled={disabled}
    >
      <Accordion.Header unstyled backgroundColor="transparent">
        <StepNavigationGroupButton
          mode={mode}
          cursor="pointer"
          testID={testID}
          label={label}
          sublabel={mode === 'compact' && collapsed && isChildSelected ? selectedChild.label : undefined}
          icon={icon}
          onPress={() => {
            if (mode === 'compact' && collapsed) {
              setCollapsed(false);
              setAllOpen();
            } else {
              // Toggle accordion group
              setOpen((prevOpen) => {
                if (prevOpen.includes(id)) {
                  return prevOpen.filter((groupId) => groupId !== id);
                }
                return [...prevOpen, id];
              });
              onPress?.();
            }
          }}
          isChildSelected={isChildSelected}
          disabled={disabled}
          hasNavigationDivider={(mode === 'full' || !collapsed) && previousSteps.includes(id)}
          collapsed={mode === 'compact' && collapsed}
        />
      </Accordion.Header>
      {(mode === 'full' || !collapsed) && (
        <Accordion.Content padding={0} backgroundColor="transparent" unstyled>
          {/* Validate that all children are StepNavigationItem components */}
          {Children.map(children, (child) => {
            if (isValidElement<StepNavigationItemProps>(child) && child.type === StepNavigationItem) {
              // Insert group context values
              return (
                <StepNavigationGroupContext.Provider
                  value={{
                    groupId: id,
                    groupName: label,
                    isSiblingSelected: isChildSelected,
                  }}
                >
                  {child}
                </StepNavigationGroupContext.Provider>
              );
            }
            throw new Error('Invalid children. Expected all children to be StepNavigationItem components.');
          })}
        </Accordion.Content>
      )}
    </Accordion.Item>
  ) : null;
}

export interface StepNavigationItemProps extends StepNavigationBaseChildProps {
  completed?: boolean;
}

/**
 * A component to render navigation step or a sub-step
 *
 * Assumptions
 *
 * - StepNavigationItem can either be a standalone step, or a sub-step of a StepNavigationGroup
 * - Each item can be completed or disabled. Disabled is the ability to open the item.
 * - If a top-level item, i.e. not part of a group, index will be set based on the order of the children
 */
function StepNavigationItem({ id, label, completed, disabled, onPress, testID }: StepNavigationItemProps) {
  const navContext = useNavigationContext();
  const groupContext = useGroupContext();
  const stepContext = useStepContext();

  const { selected, onItemSelected, previousSteps, mode, collapsed, setCollapsed, setAllOpen } = navContext;
  const hasParent = groupContext !== undefined;
  const isSelected = selected === id;
  const isRelativeSelected = !!groupContext?.isSiblingSelected;
  const tokens = getTokens();

  let icon: ReactElement<IconProps> = previousSteps.includes(id) ? (
    // Empty divider
    <YStack testID={`${label}-incomplete-icon`} width="$size.icon/size/sm" height="$size.icon/size/sm">
      <YStack
        position="absolute"
        top={-(tokens.space['step-navigation/space/step-icon-divider-gap'].val * 2)}
        bottom={-(tokens.space['step-navigation/space/step-icon-divider-gap'].val * 2)}
        left={tokens.size['icon/size/sm'].val / 2}
        outlineColor="$step-navigation/color/step-fg-subdued"
        outlineWidth={tokens.size['step-navigation/size/step-divider-width'].val / 2}
        outlineStyle="solid"
        zIndex={1}
      />
    </YStack>
  ) : (
    // Empty icon for spacing
    <YStack testID={`${label}-incomplete-icon`} width="$size.icon/size/sm" height="$size.icon/size/sm" />
  );
  // Different icons if the navigation item is a sub-step or not.
  // A sub-step is if has a parent group
  if (hasParent) {
    if (completed) {
      icon = <Check testID={`${label}-completed-icon`} color="$step-navigation/color/step-fg-subdued" />;
    }
    if (isSelected) {
      icon = <Dot testID={`${label}-selected-icon`} color="$$step-navigation/color/step-fg-default" />;
    }
  } else {
    icon = (
      <NumberIcon testID={`${label}-incomplete-icon`} variant="incomplete">{`${stepContext.stepNumber}`}</NumberIcon>
    );
    if (completed) {
      icon = (
        <NumberIcon testID={`${label}-completed-icon`} variant="complete">{`${stepContext.stepNumber}`}</NumberIcon>
      );
    }
    if (isSelected) {
      icon = <NumberIcon testID={`${label}-selected-icon`} variant="active">{`${stepContext.stepNumber}`}</NumberIcon>;
    }
  }

  return mode === 'full' || !collapsed || (isSelected && !hasParent) ? (
    <StepNavigationItemButton
      cursor="pointer"
      testID={testID}
      label={label}
      icon={icon}
      onPress={() => {
        if (mode === 'compact' && collapsed) {
          setCollapsed(false);
          setAllOpen();
        } else {
          setCollapsed(true);
          onItemSelected(id);
          onPress?.();
        }
      }}
      isSelected={isSelected}
      isRelativeSelected={isRelativeSelected}
      disabled={disabled}
      hasNavigationDivider={(mode === 'full' || !collapsed) && previousSteps.includes(id)}
      collapsed={mode === 'compact' && collapsed}
    />
  ) : null;
}

export const StepNavigation = withStaticProperties(StepNavigationBase, {
  Group: StepNavigationGroup,
  Item: StepNavigationItem,
});

/* -----------------------------------------------------------------------------------------------*/

/* -------------------------------------------------------------------------------------------------
 * StepNavigation Trigger Buttons
 * -----------------------------------------------------------------------------------------------*/

interface StepNavigationButtonBaseProps extends AccordionTriggerProps {
  selected?: boolean;
  relativeSelected?: boolean;
}

function StepNavigationButtonBase({
  onPress,
  disabled,
  children,
  selected,
  relativeSelected,
  ...props
}: StepNavigationButtonBaseProps) {
  return (
    <Accordion.Trigger
      unstyled
      aria-selected={selected}
      disabled={disabled}
      width="100%"
      paddingVertical="$space.step-navigation/space/step-padding-vertical"
      paddingHorizontal="$space.step-navigation/space/step-padding-horizontal"
      borderWidth={0}
      hoverStyle={{
        backgroundColor: '$step-navigation/color/step-bg-hover',
      }}
      backgroundColor={
        selected || relativeSelected
          ? '$step-navigation/color/step-bg-selected'
          : '$step-navigation/color/step-bg-default'
      }
      focusStyle={{
        outlineColor: '$step-navigation/color/step-focus',
        outlineWidth: '$size.step-navigation/size/step-outline-width-focus',
        outlineStyle: 'solid',
        // zIndex when being focused, so outline is always visible
        zIndex: 1,
      }}
      onPress={onPress}
      tabIndex={disabled ? -1 : 0}
      pointerEvents={disabled ? 'none' : 'auto'}
      $ltTablet={{
        paddingHorizontal: '$space.xl',
        paddingVertical: '$space.lg',
      }}
      {...props}
    >
      {children}
    </Accordion.Trigger>
  );
}

function StepNavigationGroupButton({
  label,
  sublabel,
  icon,
  isChildSelected,
  hasNavigationDivider,
  collapsed,
  mode,
  ...props
}: StepNavigationButtonBaseProps & {
  label: StepNavigationGroupProps['label'];
  sublabel?: string;
  icon: ReactElement<IconProps | YStackProps>;
  isChildSelected: boolean;
  hasNavigationDivider?: boolean;
  collapsed?: boolean;
  mode: 'full' | 'compact';
}) {
  const labelId = useId();
  const subLabelId = useId();
  return (
    <StepNavigationButtonBase
      id="step-nav-group-button"
      selected={isChildSelected}
      aria-labelledby={`${labelId}${sublabel ? ` ${subLabelId}` : ''}`}
      flex={1}
      {...props}
    >
      {({ open: groupOpen }: { open: boolean }) => {
        return (
          <XStack
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            id="step-nav-parent"
            flex={1}
            gap={mode === 'compact' ? '$space.md' : '$space.step-navigation/space/step-icon-gap'}
          >
            {/* Add divider up to selected item */}
            <YStack position="relative">
              {hasNavigationDivider ? <StepNavigationVerticalStepDivider /> : null}
              {icon}
            </YStack>
            <XStack flex={1}>
              <YStack flexShrink={1}>
                <Text
                  id={labelId}
                  variant="bodyMedium"
                  focusable={false}
                  userSelect="none"
                  pointerEvents="none"
                  color={
                    isChildSelected && !sublabel
                      ? '$step-navigation/color/step-fg-default'
                      : '$step-navigation/color/step-fg-subdued'
                  }
                >
                  {label}
                </Text>
                {sublabel ? (
                  <Text
                    id={subLabelId}
                    variant="bodyMedium"
                    focusable={false}
                    userSelect="none"
                    pointerEvents="none"
                    color="$step-navigation/color/step-fg-default"
                  >
                    {sublabel}
                  </Text>
                ) : null}
              </YStack>
            </XStack>
            <Square animation="quick" rotate={groupOpen && !collapsed ? '180deg' : '0deg'}>
              <ChevronDown
                size={mode === 'compact' ? '$size.xs' : '$size.sm'}
                color={
                  isChildSelected || collapsed
                    ? '$step-navigation/color/step-fg-default'
                    : '$step-navigation/color/step-fg-subdued'
                }
              />
            </Square>
          </XStack>
        );
      }}
    </StepNavigationButtonBase>
  );
}

function StepNavigationItemButton({
  label,
  icon,
  isSelected,
  isRelativeSelected,
  hasNavigationDivider,
  collapsed,
  ...props
}: StepNavigationButtonBaseProps & {
  label: StepNavigationItemProps['label'];
  icon: ReactElement<IconProps | YStackProps>;
  isSelected: boolean;
  isRelativeSelected: boolean;
  hasNavigationDivider?: boolean;
  collapsed?: boolean;
}) {
  const id = useId();
  return (
    <StepNavigationButtonBase
      selected={isSelected}
      relativeSelected={isRelativeSelected}
      aria-labelledby={id}
      {...props}
    >
      <XStack alignItems="center" justifyContent="space-between" gap="$space.step-navigation/space/step-icon-gap">
        <XStack gap="$space.step-navigation/space/step-icon-gap" alignItems="center" flexShrink={1}>
          <YStack>
            {hasNavigationDivider ? <StepNavigationVerticalStepDivider /> : null}
            {icon}
          </YStack>
          <Text
            id={id}
            variant="bodyMedium"
            focusable={false}
            userSelect="none"
            pointerEvents="none"
            color={isSelected ? '$step-navigation/color/step-fg-default' : '$step-navigation/color/step-fg-subdued'}
          >
            {label}
          </Text>
        </XStack>
        {collapsed ? <ChevronDown size="$size.sm" color="$step-navigation/color/step-fg-default" /> : null}
      </XStack>
    </StepNavigationButtonBase>
  );
}

function StepNavigationVerticalStepDivider() {
  const tokens = getTokens();
  return (
    <YStack
      position="absolute"
      top={
        -(tokens.space['step-navigation/space/step-padding-vertical'].val * 2) +
        tokens.space['step-navigation/space/step-icon-divider-gap'].val * 2
      }
      bottom={tokens.size['icon/size/sm'].val + tokens.space['step-navigation/space/step-icon-divider-gap'].val * 2}
      left={tokens.size['icon/size/sm'].val / 2}
      outlineColor="$step-navigation/color/step-fg-subdued"
      outlineWidth={tokens.size['step-navigation/size/step-divider-width'].val / 2}
      outlineStyle="solid"
      zIndex={1}
    />
  );
}

/* -----------------------------------------------------------------------------------------------*/

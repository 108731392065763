import type { GetProps } from 'tamagui';
import { YStack, Stack } from 'tamagui';
import { Text } from '../Text/Text';
import type { TextAreaFrameExtraProps, TextAreaStatus } from './types';
import { TextAreaBase } from './TextAreaBase';

export const TextArea = TextAreaBase.styleable<TextAreaFrameExtraProps>((props, ref) => {
  const { status, error, disabled, size, value, maxLength = 280, ...rest } = props;

  let computedStatus: TextAreaStatus | undefined = status;

  // If disabled, override status to be 'default'
  if (disabled) {
    computedStatus = 'default';
  } else if (error) {
    // If error message is given, override status to be 'error'
    computedStatus = 'error';
  }

  const remainingChars = value ? maxLength - value.length : maxLength;
  return (
    <YStack gap="$space.input/space/message-gap">
      <TextAreaBase
        ref={ref}
        status={computedStatus}
        size={size}
        textAreaDisabled={disabled}
        disabled={disabled}
        value={value}
        maxLength={maxLength}
        {...rest}
      />
      <Stack flexDirection="row" justifyContent="space-between" alignItems="flex-end">
        {error ? (
          <Text variant="bodySmall" color="$form/color/form-fg-danger" selectable={false}>
            {error}
          </Text>
        ) : null}
        <Stack flex={1} />

        <Stack
          key={value}
          animation={[
            '100ms',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ y: 0, opacity: 25 }}
          exitStyle={{ y: 0, opacity: 25 }}
        >
          <Text alignSelf="flex-end" variant="bodySmall" color="$form/color/form-fg-subdued">
            {remainingChars}
          </Text>
        </Stack>
      </Stack>
    </YStack>
  );
});

export type TextAreaProps = GetProps<typeof TextArea>;

import { XStack } from 'tamagui';
import { Stack } from '@tamagui/web';
import { ChatHelp, ChevronLeft, X } from '@cxnpl/ui/icons';
import { H5 } from '../../Heading';
import { PageFrameIconButton } from './PageFrameIconButton';

export interface PageFrameHeaderTitleBarProps {
  title?: string;
  onBack?: () => void;
  onClose?: () => void;
  onChat?: () => void;
}

export const PageFrameHeaderTitleBar = ({ title = '', onBack, onClose, onChat }: PageFrameHeaderTitleBarProps) => {
  return (
    <XStack justifyContent="space-between" alignItems="center" width="100%" gap="$2xl">
      {onClose && !onBack && !onChat ? (
        // Empty stack so the title is centred
        <Stack />
      ) : null}
      <XStack
        flex={1}
        flexGrow={1}
        justifyContent={onClose && !onBack && !onChat ? 'center' : 'flex-start'}
        alignItems="center"
        gap="$xs"
      >
        {onBack ? <PageFrameIconButton icon={<ChevronLeft />} onPress={onBack} /> : null}
        {/* Centre only if close is the only other provided prop (apart from title itself) */}
        <H5 overflow="hidden" textAlign={onClose && !onBack && !onChat ? 'center' : 'auto'} flexWrap="nowrap">
          {title}
        </H5>
      </XStack>
      {onClose || onChat ? (
        <XStack flexGrow={0} alignItems="center" gap="$2xl">
          {onChat ? <PageFrameIconButton icon={<ChatHelp />} onPress={onChat} /> : null}
          {onClose ? <PageFrameIconButton icon={<X />} onPress={onClose} /> : null}
        </XStack>
      ) : null}
    </XStack>
  );
};

'use client';

import { useStringFieldInfo, useTsController } from '@ts-react/form';
import { useId } from 'react';
import { Fieldset } from 'tamagui';
import { z } from 'zod';
import type { InputProps } from '../../Input';
import { Input } from '../../Input';
import { InputSingle } from '../../InputSingle';

export type TextFieldProps = Pick<
  InputProps,
  | 'size'
  | 'status'
  | 'disabled'
  | 'hint'
  | 'secureTextEntry'
  | 'testID'
  | 'fieldLabel'
  | 'fieldLabelVariant'
  | 'inputMode'
  | 'onBlur'
  | 'endIcon'
  | 'startIcon'
  | 'info'
> & {
  onChange?: (value: string) => void;
  /**
   * Override maxLength of the zod schema (.max function)
   */
  maxLength?: number;
  autoFocus?: boolean;
  variant?: 'singleInput' | 'default';
};

export function TextField(props: TextFieldProps): JSX.Element {
  const {
    size = '$lg',
    status,
    disabled,
    hint,
    secureTextEntry,
    testID,
    fieldLabel,
    fieldLabelVariant,
    inputMode,
    onChange,
    onBlur,
    endIcon,
    startIcon,
    maxLength: maxLengthOverride,
    autoFocus = false,
    info,
    variant = 'default',
  } = props;
  const {
    field,
    error, // zod error message
    formState,
  } = useTsController<string>();
  const { isSubmitting } = formState;
  const zodFieldInfo = useStringFieldInfo();
  const { label, placeholder, maxLength, isEmail } = zodFieldInfo;

  const id = useId();
  const isDisabled = disabled || isSubmitting;

  return (
    <Fieldset>
      {variant === 'singleInput' ? (
        <InputSingle
          ref={field.ref}
          id={id}
          testID={`${testID || field.name}-input`}
          disabled={isDisabled}
          onBlur={field.onBlur}
          onChangeText={(text) => {
            field.onChange(text);
            onChange?.(text);
          }}
          placeholder={label}
          value={field.value ?? ''}
          startIcon={startIcon}
          error={error?.errorMessage}
          hint={hint}
          status={status}
        />
      ) : (
        <Input
          minWidth="100%"
          ref={field.ref}
          id={id}
          size={size}
          // eslint-disable-next-line jsx-a11y/no-autofocus -- this is a hidden input that may need to be focused
          autoFocus={autoFocus}
          testID={`${testID || field.name}-input`}
          autoCapitalize={isEmail ? 'none' : undefined}
          disabled={isDisabled}
          inputMode={inputMode || (isEmail ? 'email' : 'text')}
          maxLength={maxLengthOverride || maxLength}
          onBlur={(e) => {
            field.onBlur();
            onBlur?.(e);
          }}
          onChangeText={(text) => {
            field.onChange(text);
            onChange?.(text);
          }}
          label={label ? label : undefined}
          fieldLabel={fieldLabel ? fieldLabel : undefined}
          fieldLabelVariant={fieldLabelVariant}
          placeholder={placeholder}
          secureTextEntry={secureTextEntry}
          value={field.value ?? ''} // default empty string to prevent "uncontrolled to controlled" react warning
          error={error?.errorMessage}
          hint={hint}
          status={status}
          startIcon={startIcon}
          endIcon={endIcon}
          info={info}
          prioritiseEndIcon
        />
      )}
    </Fieldset>
  );
}

function ValidPayIdNumber<T extends string>(key: T, value: string, ctx: z.RefinementCtx) {
  /**
   * Phone number format:
   * [+Country Code]-[Number]
   *
   * - Country code: Starts with '+' followed by 1 to 3 digits.
   * - Number: Starts with a digit between 1 and 9, followed by 1 to 29 digits.
   */
  if (!/^\+[0-9]{1,3}-[1-9][0-9]{0,28}$/.test(value)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Invalid phone number format. Please use the format: [+Country Code]-[Number]',
      path: [key],
    });
  }
}

export const TextFieldValidators = {
  ValidPayIdNumber,
};

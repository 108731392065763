import { useDatePickerContext } from '@rehookify/datepicker';
import { View } from 'tamagui';
import { Text } from '../../Text';
import { swapOnClick } from '../helpers';

export const YearPicker = ({ onChange }: { onChange?: (evt: MouseEvent, date: Date) => void }) => {
  const {
    data: { years, calendars },
    propGetters: { yearButton },
  } = useDatePickerContext();
  const [selectedYear] = calendars;

  return (
    <View flexDirection="row" rowGap="$space.xl" flexWrap="wrap" justifyContent="space-evenly">
      {years.map((year) => (
        <View
          key={year.$date.toString()}
          themeInverse={year.year === Number(selectedYear?.year)}
          hoverStyle={{ backgroundColor: '$foreground/surface-inverse-subdued' }}
          backgroundColor={
            year.year === Number(selectedYear?.year) ? '$foreground/surface-inverse' : '$background/transparent'
          }
          minWidth="30%"
          minHeight="$size.md"
          justifyContent="center"
          alignItems="center"
          borderRadius="$radius.3xl"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Suppressing error for implicit mouseEvent
          {...swapOnClick(yearButton(year, { onClick: onChange as any }))}
          tabIndex={0}
        >
          <Text variant="bodySmall" textAlign="center">
            {year.year}
          </Text>
        </View>
      ))}
    </View>
  );
};

import { getImageSrc } from '../../utils';
import type { Branding } from '../brandingTypes';
import { default as cardNumberACT } from './ACT-card-number.svg';
import { default as cardNumberNSW } from './NSW-card-number.svg';
import { default as cardNumberNTPost01Nov2020 } from './NT-card-number-post-01-nov-2020.svg';
import { default as cardNumberNTPre01Nov2020 } from './NT-card-number-pre-01-nov-2020.svg';
import { default as cardNumberQLDBack } from './QLD-card-number-back.svg';
import { default as cardNumberQLDFront } from './QLD-card-number-front.svg';
import { default as cardNumberSA } from './SA-card-number.svg';
import { default as cardNumberTAS } from './TAS-card-number.svg';
import { default as cardNumberVICPost07Nov2022 } from './VIC-card-number-post-07-nov-2022.svg';
import { default as cardNumberVICPre07Nov2022 } from './VIC-card-number-pre-07-nov-2022.svg';
import { default as cardNumberWA } from './WA-card-number.svg';
import { default as backgroundImage } from './background.svg';
import { default as bpay } from './bpay.svg';
import { default as businessTransaction } from './business-transaction-icon.svg';
import { default as business } from './business.svg';
import { default as cardBackCvcImage } from './card-back-cvc.png';
import { default as cardBackImage } from './card-back.png';
import { default as cardGeneral } from './card-general.svg';
import { default as cardImage } from './card.png';
import { default as chat } from './chat-icon.svg';
import { default as howToUseApplePayImage } from './howToUseApplePay.png';
import { default as applePayWithHand } from './applePayWithHand.png';
import { default as applePayImages } from './applePayImages.png';
import { default as applePayActivated } from './applePayActivated.png';
import { default as addToAppleWalletButton } from './addToAppleWalletButton.png';
import { default as addGooglePay } from './addGooglePay.svg';
import { default as logoImage } from './cxnpl_logo.svg';
import { default as tapToPay } from './tap-to-pay.svg';
import { default as faviconPng } from './favicon.png';
import { default as faviconSvg } from './favicon.svg';
import { default as invite } from './invite.svg';
import { default as lendingCalc } from './lending-calc-icon.svg';
import { default as lifeRing } from './life-ring.svg';
import { default as loader } from './loader.json';
import { default as merchantAccount } from './merchant-account-icon.svg';
import { default as myob } from './myob.svg';
import { default as profile } from './profile.svg';
import { default as savings } from './savings-icon.svg';
import { default as sendMoney } from './send-money.svg';
import { default as sendingPayment } from './sendingPayment.json';
import { default as sentPayment } from './sentPayment.json';
import { default as businessLoan } from './unsecured-business-loan.svg';
import { default as businessOverdraft } from './unsecured-business-overdraft.svg';
import { default as xero } from './xero.svg';
import { default as passportNumber } from './passport-number.svg';
import { default as medicareCardNumber } from './medicare-card-number.svg';
import { default as medicareCardIndividualReferenceNumber } from './individual-reference-number.svg';
import { default as refinanceHomeLoan } from './refinance-home-loan.svg';
import { default as faceScan } from './face-scan.svg';
import { default as fingerprint } from './fingerprint.svg';
import { default as invoices } from './invoices.svg';
import { default as headerBackground } from './header-background.png';

const brandName = 'Constantinople';
const productName = 'CXNPL';

const branding: Branding = {
  brandName,
  productName,
  businessName: 'Cxnpl Business Name',
  abn: '00 000 000 000',
  creditLicence: '000000',
  brandNameShort: 'CXNPL',
  pageTitle: brandName,
  pageDescription: brandName,
  transactionsAccountMoreInfo: 'https://www.cxnpl.com/',
  savingsAccountMoreInfo: 'https://www.cxnpl.com/',
  overdraftMoreInfo: 'https://www.cxnpl.com/',
  loanMoreInfo: 'https://www.cxnpl.com/',
  vehicleLoanMoreInfo: 'https://www.cxnpl.com/',
  invoicesMoreInfo: 'https://www.cxnpl.com/',
  appleAppStoreUrl: 'https://apps.apple.com/au/app/great-southern-bank-business/id6469234503',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=au.com.gsb.business&hl=en_AU&gl=AU',
  assets: {
    loader,
    sendingPayment,
    sentPayment,
    logo: {
      src: getImageSrc(logoImage),
      width: 53,
      height: 40,
    },
    background: {
      src: getImageSrc(backgroundImage),
    },
    favicon: {
      png: getImageSrc(faviconPng),
      svg: getImageSrc(faviconSvg),
    },
    businessTransaction: {
      src: getImageSrc(businessTransaction),
      height: 80,
      width: 120,
    },
    businessLoan: {
      src: getImageSrc(businessLoan),
      height: 80,
      width: 120,
    },
    businessOverdraft: {
      src: getImageSrc(businessOverdraft),
      height: 80,
      width: 120,
    },
    savings: {
      src: getImageSrc(savings),
      height: 80,
      width: 120,
    },
    card: {
      src: getImageSrc(cardImage),
      width: 329,
      height: 207,
    },
    cardBack: {
      src: getImageSrc(cardBackImage),
      width: 312,
      height: 197,
    },
    cardBackCvc: {
      src: getImageSrc(cardBackCvcImage),
      width: 528,
      height: 334,
    },
    howToUseApplePayImage: {
      src: getImageSrc(howToUseApplePayImage),
      width: 1074,
      height: 126,
    },
    applePayWithHand: {
      src: getImageSrc(applePayWithHand),
      width: 1170,
      height: 735,
    },
    applePayImages: {
      src: getImageSrc(applePayImages),
      width: 514,
      height: 145,
    },
    applePayActivated: {
      src: getImageSrc(applePayActivated),
      width: 373,
      height: 501,
    },
    addToAppleWalletButton: {
      src: getImageSrc(addToAppleWalletButton),
      width: 1074,
      height: 144,
    },
    addGooglePay: {
      src: getImageSrc(addGooglePay),
      width: 328,
      height: 42,
    },
    lendingCalc: {
      src: getImageSrc(lendingCalc),
      height: 80,
      width: 120,
    },
    business: {
      src: getImageSrc(business),
      height: 80,
      width: 80,
    },
    invite: {
      src: getImageSrc(invite),
      height: 80,
      width: 80,
    },
    cardGeneral: {
      src: getImageSrc(cardGeneral),
      height: 48,
      width: 48,
    },
    sendMoney: {
      src: getImageSrc(sendMoney),
      height: 48,
      width: 48,
    },
    profile: {
      src: getImageSrc(profile),
      height: 48,
      width: 48,
    },
    bpay: {
      src: getImageSrc(bpay),
      height: 48,
      width: 48,
    },
    cardNumberACT: {
      src: getImageSrc(cardNumberACT),
      height: 142,
      width: 222,
    },
    cardNumberNTPost01Nov2020: {
      src: getImageSrc(cardNumberNTPost01Nov2020),
      height: 142,
      width: 222,
    },
    cardNumberNTPre01Nov2020: {
      src: getImageSrc(cardNumberNTPre01Nov2020),
      height: 142,
      width: 222,
    },
    cardNumberQLDFront: {
      src: getImageSrc(cardNumberQLDFront),
      height: 142,
      width: 222,
    },
    cardNumberQLDBack: {
      src: getImageSrc(cardNumberQLDBack),
      height: 142,
      width: 222,
    },
    cardNumberNSW: {
      src: getImageSrc(cardNumberNSW),
      height: 142,
      width: 222,
    },
    cardNumberSA: {
      src: getImageSrc(cardNumberSA),
      height: 142,
      width: 222,
    },
    cardNumberTAS: {
      src: getImageSrc(cardNumberTAS),
      height: 142,
      width: 222,
    },
    cardNumberVICPost07Nov2022: {
      src: getImageSrc(cardNumberVICPost07Nov2022),
      height: 142,
      width: 222,
    },
    cardNumberVICPre07Nov2022: {
      src: getImageSrc(cardNumberVICPre07Nov2022),
      height: 142,
      width: 222,
    },
    cardNumberWA: {
      src: getImageSrc(cardNumberWA),
      height: 142,
      width: 222,
    },
    merchantAccount: {
      src: getImageSrc(merchantAccount),
      height: 80,
      width: 120,
    },
    xeroAccount: {
      src: getImageSrc(xero),
      height: 32,
      width: 32,
    },
    myobAccount: {
      src: getImageSrc(myob),
      height: 24,
      width: 45.55,
    },
    lifeRing: {
      src: getImageSrc(lifeRing),
      height: 88,
      width: 88,
    },
    documentNumberPassport: {
      src: getImageSrc(passportNumber),
      height: 138,
      width: 222,
    },
    medicareCardNumber: {
      src: getImageSrc(medicareCardNumber),
      height: 139,
      width: 222,
    },
    medicareCardIndividualReferenceNumber: {
      src: getImageSrc(medicareCardIndividualReferenceNumber),
      height: 139,
      width: 222,
    },
    refinanceHomeLoan: {
      src: getImageSrc(refinanceHomeLoan),
      height: 48,
      width: 48,
    },
    faceScan: {
      src: getImageSrc(faceScan),
      height: 175,
      width: 166,
    },
    fingerprint: {
      src: getImageSrc(fingerprint),
      height: 175,
      width: 166,
    },
    tapToPay: {
      src: getImageSrc(tapToPay),
      height: 32,
      width: 32,
    },
    invoices: {
      src: getImageSrc(invoices),
      height: 32,
      width: 32,
    },
    headerBackground: {
      src: getImageSrc(headerBackground),
      height: 412,
      width: 732,
    },
    chat: {
      src: getImageSrc(chat),
      height: 48,
      width: 48,
    },
  },
};

export default branding;

'use client';

import type { GetProps } from '@tamagui/core';
import { styled } from '@tamagui/core';
import { AlertCircle, Check } from '@tamagui/lucide-icons';
import type { ReactElement } from 'react';
import { YStack } from 'tamagui';
import { Text } from '../Text';
import { InputSingleBase } from './InputSingleBase';

/* -------------------------------------------------------------------------------------------------
 * Usable Input Component
 * - Defined statuses with icons for each
 * - Render hint and error messages below input
 * -----------------------------------------------------------------------------------------------*/

export type InputSingleStatus = 'default' | 'success' | 'error';

const InputSingleFrame = styled(InputSingleBase, {
  name: 'InputSingleFrame',
  variants: {
    status: {
      default: {},
      success: {},
      error: {},
    },
  } as const,

  defaultVariants: {
    status: 'default',
  },
});

interface InputFrameExtraProps {
  hint?: string;
  /**
   * Error message to display. If given, will set the `status` prop as error
   */
  error?: string;
  /**
   * Whether to prioritise rendering the `endIcon` if provided, over any status icons
   */
  prioritiseEndIcon?: boolean;
}

export const InputSingle = InputSingleFrame.styleable<InputFrameExtraProps>((props, ref) => {
  const { status, endIcon, hint, error, prioritiseEndIcon = false, disabled, value, maxLength, ...rest } = props;

  let computedStatus: InputSingleStatus | undefined = status;
  const remainingChars = maxLength ? maxLength - (value?.length || 0) : undefined;

  // If disabled, override status to be 'default'
  if (disabled) {
    computedStatus = 'default';
  } else if (error) {
    // If error message is given, override status to be 'error'
    computedStatus = 'error';
  }

  let statusEndIcon: ReactElement | undefined = endIcon;
  if (!prioritiseEndIcon || !endIcon) {
    if (computedStatus === 'success') {
      statusEndIcon = <Check color="$alert/color/success/alert-icon-success" />;
    } else if (computedStatus === 'error') {
      statusEndIcon = <AlertCircle color="$alert/color/danger/alert-icon-danger" />;
    }
  }

  return (
    <YStack gap="$space.input/space/message-gap">
      <InputSingleFrame
        ref={ref}
        status={computedStatus}
        endIcon={statusEndIcon}
        inputDisabled={disabled}
        hasError={!!error}
        disabled={disabled}
        value={value}
        {...rest}
      />
      {error ? (
        <Text variant="bodySmall" color="$form/color/form-fg-danger" selectable={false}>
          {error}
        </Text>
      ) : null}
      {hint && !error ? (
        <Text variant="bodySmall" color="$form/color/form-fg-subdued" selectable={false}>
          {hint}
        </Text>
      ) : null}
      {/* Show a max length option */}
      {maxLength ? (
        <YStack
          key={value}
          animation={[
            '100ms',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ y: 0, opacity: 25 }}
          exitStyle={{ y: 0, opacity: 25 }}
        >
          <Text alignSelf="flex-end" variant="bodySmall" color="$form/color/form-fg-subdued">
            {remainingChars}
          </Text>
        </YStack>
      ) : undefined}
    </YStack>
  );
});

export type SingleInputProps = GetProps<typeof InputSingle>;

'use client';

import { z } from 'zod';
import { Fieldset, YStack } from 'tamagui';
import { HelpCircle } from '@tamagui/lucide-icons';
import { useState } from 'react';
import type { TypeaheadFieldProps } from '../TypeaheadField';
import { TypeaheadField } from '../TypeaheadField';
import { Button } from '../../../../Button';
import { Modal, ModalDescription } from '../../../../Modal';
import { Anchor } from '../../../../Anchor';

export const createIndustryFieldItemSchema = (params?: z.RawCreateParams) =>
  z.object(
    {
      anzsicCodeBase: z.string(),
      divisionCode: z.string(),
      divisionName: z.string(),
      industryCode: z.string(),
      industryName: z.string(),
    },
    params ?? {
      required_error: 'Please select an industry',
    }
  );

export type IndustryFieldSchema = ReturnType<typeof createIndustryFieldItemSchema>;
export type IndustryFieldItem = z.infer<IndustryFieldSchema>;
export type IndustryFieldProps = TypeaheadFieldProps<IndustryFieldItem>;

export function IndustryField(props: IndustryFieldProps): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <YStack gap="$2xl" maxWidth="100%" minWidth="100%">
      <Fieldset>
        <TypeaheadField<IndustryFieldItem> {...props} />
      </Fieldset>
      {!props.disabled ? (
        <YStack alignItems="flex-start">
          <Button
            startIcon={<HelpCircle />}
            variant="no-outline"
            mode="primary"
            size="sm"
            onPress={() => {
              setIsOpen(true);
            }}
          >
            {`Can't find your industry?`}
          </Button>
        </YStack>
      ) : null}
      <Modal
        testID="cant-find-industry-modal"
        open={isOpen}
        title="Can't find your industry?"
        setModalOpen={setIsOpen}
        buttons={[
          {
            onPress: () => {
              setIsOpen(false);
            },
            variant: 'filled',
            mode: 'primary',
            label: 'Okay',
          },
        ]}
      >
        <ModalDescription>
          Try searching a different word. If you&#39;re not sure which industry to use, more information can be found at
          the{' '}
          <Anchor
            variant="linkSmall"
            href="https://www.abs.gov.au/statistics/classifications/australian-and-new-zealand-standard-industrial-classification-anzsic/latest-release"
            target="_blank"
            rel="noopener noreferrer"
          >
            Australian Bureau of Statistics
          </Anchor>
          , which may help you decide.
        </ModalDescription>
      </Modal>
    </YStack>
  );
}

'use client';

import { XStack } from 'tamagui';
import type { ReactNode } from 'react';
import { usePageFrameContext } from './PageFrameContext';

export interface PageFrameHeaderProps {
  children: ReactNode;
}

export const PageFrameHeader = ({ children }: PageFrameHeaderProps) => {
  const { setHeaderHeight } = usePageFrameContext();
  return (
    <XStack
      alignItems="center"
      width="100%"
      onLayout={(e) => {
        setHeaderHeight(Math.round(e.nativeEvent.layout.height));
      }}
      paddingHorizontal="$xl"
    >
      {children}
    </XStack>
  );
};

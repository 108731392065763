import type { GetProps, TextProps, GetThemeValueForKey } from '@tamagui/web';
import { Stack, createStyledContext, styled, withStaticProperties, Text } from '@tamagui/web';
import type { ReactElement } from 'react';
import { cloneElement, useContext } from 'react';
import { Spinner } from 'tamagui';

type ButtonColor = 'primary' | 'secondary' | 'tertiary' | 'danger' | 'inverse';
type ExtendedButtonColor = ButtonColor | 'disabled';

interface ButtonContextValues {
  mode?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'inverse';
  variant?: 'filled' | 'outlined' | 'no-outline';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  disabled?: boolean;
  fullWidth?: boolean;
}

interface ButtonVariantProps extends GetProps<typeof Stack> {
  color: GetThemeValueForKey<'color'>;
}

// All these values will be passed into child components of ButtonBase
const ButtonContext = createStyledContext<ButtonContextValues>({
  mode: 'primary',
  variant: 'filled',
  size: 'lg',
  disabled: false,
  fullWidth: false,
});

const buttonVariants: {
  [mode in ExtendedButtonColor]: {
    filled: ButtonVariantProps;
    outlined: ButtonVariantProps;
    'no-outline': ButtonVariantProps | TextProps;
  };
} = {
  primary: {
    filled: {
      color: '$button/color/button-primary-fg',
      backgroundColor: '$button/color/button-primary-bg-default',
      borderColor: '$button/color/button-primary-border',
      hoverStyle: {
        backgroundColor: '$button/color/button-primary-bg-hover',
        borderColor: '$button/color/button-primary-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-primary-bg-hover',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-primary-bg-press',
        borderColor: '$button/color/button-primary-border-press',
      },
    },
    outlined: {
      color: '$button/color/button-primary-text',
      backgroundColor: '$background/transparent',
      borderColor: '$button/color/button-outlined-primary-border',
      hoverStyle: {
        backgroundColor: '$button/color/button-outlined-primary-bg-hover',
        borderColor: '$button/color/button-outlined-primary-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-outlined-primary-bg-hover',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-outlined-primary-bg-press',
        borderColor: '$button/color/button-outlined-primary-border-press',
      },
    },
    'no-outline': {
      color: '$button/color/button-primary-text',
      backgroundColor: '$background/transparent',
      borderWidth: 0,
      focusStyle: {
        backgroundColor: '$background/transparent',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-outlined-primary-bg-press',
        borderColor: '$button/color/button-outlined-primary-border-press',
      },
    },
  },
  secondary: {
    filled: {
      color: '$button/color/button-secondary-fg',
      backgroundColor: '$button/color/button-secondary-bg-default',
      borderColor: '$button/color/button-secondary-border',
      hoverStyle: {
        backgroundColor: '$button/color/button-secondary-bg-hover',
        borderColor: '$button/color/button-secondary-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-secondary-bg-hover',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-secondary-bg-press',
        borderColor: '$button/color/button-secondary-border-press',
      },
    },
    outlined: {
      color: '$button/color/button-secondary-text',
      backgroundColor: '$button/color/button-outlined-secondary-bg',
      borderColor: '$button/color/button-outlined-secondary-border',
      hoverStyle: {
        backgroundColor: '$button/color/button-outlined-secondary-bg-hover',
        borderColor: '$button/color/button-outlined-secondary-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-outlined-secondary-bg-hover',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-outlined-secondary-bg-press',
        borderColor: '$button/color/button-outlined-secondary-border-press',
      },
    },
    'no-outline': {
      color: '$button/color/button-secondary-text',
      backgroundColor: '$background/transparent',
      borderWidth: 0,
      focusStyle: {
        backgroundColor: '$background/transparent',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$background/transparent',
        borderColor: '$button/color/button-secondary-border-press',
      },
    },
  },
  tertiary: {
    filled: {
      color: '$button/color/button-tertiary-fg',
      backgroundColor: '$button/color/button-tertiary-bg-default',
      borderColor: '$button/color/button-tertiary-border',
      hoverStyle: {
        backgroundColor: '$button/color/button-tertiary-bg-hover',
        borderColor: '$button/color/button-tertiary-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-tertiary-bg-hover',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-tertiary-bg-press',
        borderColor: '$button/color/button-tertiary-border-press',
      },
    },
    outlined: {
      color: '$button/color/button-tertiary-text',
      backgroundColor: '$button/color/button-outlined-tertiary-bg',
      borderColor: '$button/color/button-outlined-tertiary-border',
      hoverStyle: {
        backgroundColor: '$button/color/button-outlined-tertiary-bg',
        borderColor: '$button/color/button-outlined-tertiary-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-outlined-tertiary-bg-hover',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-outlined-tertiary-bg-press',
        borderColor: '$button/color/button-outlined-tertiary-border-press',
      },
    },
    'no-outline': {
      color: '$button/color/button-tertiary-text',
      backgroundColor: '$background/transparent',
      borderWidth: 0,

      focusStyle: {
        backgroundColor: '$background/transparent',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$background/transparent',
        borderColor: '$button/color/button-tertiary-border-press',
      },
    },
  },
  danger: {
    filled: {
      color: '$button/color/button-danger-fg',
      backgroundColor: '$button/color/button-danger-bg-default',
      borderColor: '$button/color/button-danger-border',
      hoverStyle: {
        backgroundColor: '$button/color/button-danger-bg-hover',
        borderColor: '$button/color/button-danger-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-danger-bg-hover',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-danger-bg-press',
        borderColor: '$button/color/button-danger-border-press',
      },
    },
    outlined: {
      color: '$button/color/button-danger-text',
      backgroundColor: '$button/color/button-outlined-danger-bg',
      borderColor: '$button/color/button-outlined-danger-border',
      hoverStyle: {
        backgroundColor: '$button/color/button-outlined-danger-bg-hover',
        borderColor: '$button/color/button-outlined-danger-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-outlined-danger-bg-hover',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-outlined-danger-bg-press',
        borderColor: '$button/color/button-outlined-danger-border-press',
      },
    },
    'no-outline': {
      color: '$button/color/button-danger-text',
      backgroundColor: '$background/transparent',
      borderWidth: 0,

      focusStyle: {
        backgroundColor: '$background/transparent',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$background/transparent',
        borderColor: '$button/color/button-danger-border-press',
      },
    },
  },
  inverse: {
    filled: {
      color: '$button/color/button-inverse-fg',
      backgroundColor: '$button/color/button-inverse-bg-default',
      borderColor: '$button/color/button-inverse-border',
      hoverStyle: {
        backgroundColor: '$button/color/button-inverse-bg-hover',
        borderColor: '$button/color/button-inverse-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-inverse-bg-hover',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-inverse-bg-press',
        borderColor: '$button/color/button-inverse-border-press',
      },
    },
    outlined: {
      color: '$button/color/button-inverse-fg',
      backgroundColor: '$background/transparent',
      borderColor: '$button/color/button-inverse-border',
      hoverStyle: {
        backgroundColor: '$button/color/button-inverse-bg-hover',
        borderColor: '$button/color/button-inverse-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-inverse-bg-hover',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-inverse-bg-press',
        borderColor: '$button/color/button-inverse-border-press',
      },
    },
    'no-outline': {
      color: '$button/color/button-inverse-fg',
      backgroundColor: '$background/transparent',
      borderWidth: 0,
      focusStyle: {
        backgroundColor: '$background/transparent',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-inverse-bg-press',
        borderColor: '$button/color/button-inverse-border-press',
      },
    },
  },
  disabled: {
    filled: {
      color: '$button/color/button-disabled-fg',
      backgroundColor: '$button/color/button-disabled-bg',
      borderColor: '$button/color/button-disabled-bg',
      hoverStyle: {
        backgroundColor: '$button/color/button-disabled-bg',
        borderColor: '$button/color/button-disabled-bg',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-disabled-bg',
        borderColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-disabled-bg',
        borderColor: '$button/color/button-disabled-bg',
      },
    },
    outlined: {
      color: '$button/color/button-disabled-fg',
      backgroundColor: '$button/color/button-disabled-bg',
      borderColor: '$border/disabled',
      hoverStyle: {
        backgroundColor: '$button/color/button-disabled-bg',
        borderColor: '$button/color/button-disabled-bg',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-disabled-bg',
        borderColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-disabled-bg',
        borderColor: '$button/color/button-disabled-bg',
      },
    },
    'no-outline': {
      color: '$button/color/button-disabled-fg',
      backgroundColor: '$background/transparent',
      borderWidth: 0,
    },
  },
} as const;

const ButtonBaseFrame = styled(Stack, {
  name: 'Button',
  context: ButtonContext,

  // Default styling from Tamagui's Button
  tag: 'button',
  role: 'button',
  focusable: true,
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  cursor: 'pointer',

  borderWidth: 1, // TODO There is no semantic token for space of 1, will need to update Figma variables
  borderRadius: '$button/radius/button-radius',
  gap: '$xs',

  variants: {
    mode: { primary: {}, secondary: {}, tertiary: {}, danger: {}, inverse: {} },
    // Override color styles if needed
    variant: {
      //TODO: There is a way to make this into a spread for sure
      filled: (_, { props }) => {
        // These are props which passed into the component
        const { mode = 'primary', disabled } = props as GetProps<typeof Stack> & ButtonContextValues;
        const colorVariant: ExtendedButtonColor = disabled ? 'disabled' : mode;
        return buttonVariants[colorVariant].filled;
      },
      outlined: (_, { props }) => {
        // These are props which passed into the component
        const { mode = 'primary', disabled } = props as GetProps<typeof Stack> & ButtonContextValues;
        const colorVariant: ExtendedButtonColor = disabled ? 'disabled' : mode;
        return buttonVariants[colorVariant].outlined;
      },
      'no-outline': (_, { props }) => {
        // These are props which passed into the component
        const { mode = 'primary', disabled } = props as GetProps<typeof Stack> & ButtonContextValues;
        const colorVariant: ExtendedButtonColor = disabled ? 'disabled' : mode;
        return buttonVariants[colorVariant]['no-outline'];
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },

    // Override variant styles if needed
    disabled: {
      true: {
        pointerEvents: 'none',
      },
    },
    size: {
      sm: {
        paddingHorizontal: '$sm',
        paddingVertical: '$xs',
        height: '$button/size/sm',
      },
      md: {
        paddingHorizontal: '$lg',
        paddingVertical: '$sm',
        height: '$button/size/md',
      },
      lg: {
        gap: '$sm',
        paddingHorizontal: '$xl',
        paddingVertical: '$md',
        height: '$button/size/lg',
      },
      xl: {
        gap: '$sm',
        paddingHorizontal: '$2xl',
        paddingVertical: '$md',
        height: '$button/size/xl',
      },
    },
  },
} as const);

// Wrapper to enforce correct ordering of props
const ButtonFrame = ButtonBaseFrame.styleable(({ variant, mode, size, disabled, ...props }, ref) => {
  return (
    // Need props to be in this order: color > variant > disabled for cascade styling in variants to work
    <ButtonBaseFrame ref={ref} mode={mode} variant={variant} disabled={disabled} size={size} {...props} />
  );
});

const ButtonText = styled(Text, {
  name: 'ButtonText',
  context: ButtonContext,

  // Default styling from Tamagui's ButtonText
  userSelect: 'none',
  cursor: 'pointer',
  flexGrow: 0,
  flexShrink: 1,
  ellipse: true,
  // color will be set by ButtonBase, not in ButtonText
  variants: {
    size: {
      sm: {
        variant: 'bodySmallEm',
      },
      md: {
        variant: 'bodySmallEm',
      },
      lg: {
        variant: 'bodyMediumEm',
      },
      xl: {
        variant: 'bodyLargeEm',
      },
    },
  },
} as const);

const ButtonIcon = (props: { children: React.ReactElement }) => {
  const { size, mode = 'primary', variant = 'filled', disabled } = useContext(ButtonContext.context);

  const getSize = () => {
    switch (size) {
      case 'xl':
        return 24;
      case 'lg':
      case 'md':
      case 'sm':
      default:
        return 16;
    }
  };

  const getColor = () => {
    const colorVariant = disabled ? 'disabled' : mode;
    return buttonVariants[colorVariant][variant].color;
  };

  return cloneElement(props.children, {
    size: getSize(),
    color: getColor(),
  });
};

export const ButtonBase = withStaticProperties(ButtonFrame, {
  // Props: ButtonContext.Provider, // Unused. Values will be passed directly to ButtonBase props, instead of being passed to this wrapping component then passed down to ButtonBase using context
  Text: ButtonText,
  Icon: ButtonIcon,
});

export type ButtonProps = Omit<GetProps<typeof ButtonFrame>, 'onPress'> & {
  children?: string | ReactElement;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  onPress?: (() => Promise<void>) | (() => void);
  mode?: ButtonContextValues['mode'];
  variant?: ButtonContextValues['variant'];
  size?: ButtonContextValues['size'];
  disabled?: boolean;
  testID?: string;
  /**
   * Whether to display a loading spinner. Will take precedence over any given endIcon
   */
  loading?: boolean;
  fullWidth?: boolean;
  textProps?: TextProps;
};

export const Button = ({
  children,
  startIcon,
  endIcon,
  mode = 'primary',
  variant = 'filled',
  size = 'lg',
  fullWidth = false,
  testID,
  loading,
  textProps,
  ...props
}: ButtonProps) => {
  return (
    <ButtonBase testID={testID} mode={mode} variant={variant} size={size} fullWidth={fullWidth} {...props}>
      {/*
        ButtonBase.Icon and ButtonBase.Text will receive all ButtonContext values.
      */}
      {startIcon ? <ButtonBase.Icon>{startIcon}</ButtonBase.Icon> : null}
      {typeof children === 'string' ? <ButtonBase.Text {...textProps}>{children}</ButtonBase.Text> : children}
      {loading ? (
        <ButtonBase.Icon>
          <Spinner testID={testID ? `${testID}-spinner` : undefined} />
        </ButtonBase.Icon>
      ) : null}
      {endIcon && !loading ? <ButtonBase.Icon>{endIcon}</ButtonBase.Icon> : null}
    </ButtonBase>
  );
};

'use client';
import { useStringFieldInfo, useTsController } from '@ts-react/form';
import { extend } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// eslint-disable-next-line no-restricted-imports -- Disable this rule because we need to import the t function from i18next
import { useId } from 'react';
import type { GetProps } from 'tamagui';
import { Fieldset, YStack } from 'tamagui';
import { DatePicker } from '../../DatePicker';
import type { InputProps } from '../../Input';
import { Input } from '../../Input';

export const partialDateRegexDDMMYYYY = /^\d{0,2}\/?$|^\d{0,2}\/{1}\d{0,2}$|^\d{0,2}\/{1}\d{0,2}\/{1}\d{0,4}$/;
export const partialDateRegexMMYYYY = /^\d{0,2}\/?$|^\d{0,2}\/{1}\d{0,4}$/;
export const partialDateRegexYYYY = /^\d{0,4}$/;
export const dayFinishedRegex = /^\d{2}$/;
export const dayMonthFinishedRegex = /^\d{1,2}\/\d{2}$/;

extend(customParseFormat);

type DateFormat = 'DD/MM/YYYY' | 'MM/YYYY' | 'YYYY';

const getDateFormat = (dateFormat: DateFormat, text: string) => {
  switch (dateFormat) {
    case 'YYYY':
      return partialDateRegexYYYY.test(text);

    case 'MM/YYYY':
      return partialDateRegexMMYYYY.test(text);

    case 'DD/MM/YYYY':
      return partialDateRegexDDMMYYYY.test(text);
  }
};

export type DateFieldProps = Pick<
  InputProps,
  'size' | 'status' | 'disabled' | 'hint' | 'testID' | 'fieldLabel' | 'fieldLabelVariant'
> & {
  dateFormat?: DateFormat;
  withDatePicker?: boolean;
  datePickerTitle?: string;
  info?: { title: string; body: string };
};

export function DateField({
  size = '$lg',
  status,
  disabled,
  hint,
  testID,
  fieldLabel,
  fieldLabelVariant,
  info,
  dateFormat = 'DD/MM/YYYY',
  withDatePicker,
  datePickerTitle,
}: DateFieldProps): JSX.Element {
  const { field, error, formState } = useTsController<string>();
  const { isSubmitting } = formState;
  const zodFieldInfo = useStringFieldInfo();
  const { label, placeholder } = zodFieldInfo;
  const id = useId();
  const isDisabled = disabled || isSubmitting;

  const onChangeText = (text: string) => {
    const passDateFormat = getDateFormat(dateFormat, text);
    if (!passDateFormat) {
      return;
    }
    const backspacedText = text.length < Number(field.value?.length);
    if (backspacedText) {
      field.onChange(text);
      return;
    }

    // Insert `/` between Day/Month and Month/Year
    if (dateFormat !== 'YYYY') {
      if (dayFinishedRegex.test(text) || (dayMonthFinishedRegex.test(text) && dateFormat === 'DD/MM/YYYY')) {
        field.onChange(`${text}/`);
        return;
      }
    }
    field.onChange(text);
  };

  const inputProps = {
    selectTextOnFocus: true,
    maxLength: 10,
    id,
    size,
    testID: `${testID || field.name}-input`,
    disabled: isDisabled,
    inputMode: 'numeric',
    onChangeText,
    fieldLabel,
    fieldLabelVariant,
    info,
    label: label ? label : undefined,
    placeholder,
    value: field.value ?? '',
    error: error?.errorMessage,
    hint,
    status,
  } satisfies GetProps<typeof Input>;

  return (
    <Fieldset>
      <YStack>
        {withDatePicker ? (
          <DatePicker title={datePickerTitle} dateFormat={dateFormat} onDateSelect={onChangeText} {...inputProps} />
        ) : (
          <Input {...inputProps} ref={field.ref} />
        )}
      </YStack>
    </Fieldset>
  );
}

/* eslint-disable no-console -- debugging*/
import type { UseFormProps } from 'react-hook-form';
// eslint-disable-next-line no-restricted-imports -- useForm hook is created here - it is a wrapper around react-hook-form's useForm
import { useForm as useRHFForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { z } from 'zod';

export function useForm<T extends z.Schema>(schema: T, options?: UseFormProps<z.infer<T>>, debug = false) {
  const form = useRHFForm<z.infer<T>>({
    resolver: async (data, context, opts) => {
      if (debug) {
        console.log('formData', data);
        console.log('validation result', await zodResolver(schema)(data, context, opts));
      }
      return zodResolver(schema)(data, context, opts);
    },
    ...options,
  });

  return form;
}

'use client';

import { useState } from 'react';
import type { StackProps, TabLayout, TabsTabProps } from 'tamagui';
import { AnimatePresence, Tabs, XStack, YStack, getTokenValue } from 'tamagui';
import { DotLarge } from '@cxnpl/ui/icons';
import { Text } from '../Text';

// Tab Item V2 Tabs
const TabItem = ({
  value,
  label,
  selected,
  notification,
  onInteraction,
}: {
  value: string;
  label: string;
  selected: boolean;
  notification: boolean;
  onInteraction: (type: 'select' | 'focus' | 'hover', layout: TabLayout | null) => void;
}) => {
  return (
    <Tabs.Tab
      unstyled
      // TODO - Investigate ways to not fix the height / different text sizes
      height={36}
      value={value}
      flex={1}
      onInteraction={onInteraction}
      paddingHorizontal={getTokenValue('$tabs/space/horizontal-padding-sm') as number}
    >
      <XStack justifyContent="center" alignItems="center" gap={getTokenValue('$space.input/space/icon-gap') as number}>
        <Text
          animation="quick"
          variant="bodySmallEm"
          color={selected ? '$tabs/color/tab-fg-selected' : '$tabs/color/tab-fg-default'}
        >
          {label}
        </Text>
        {notification ? (
          <DotLarge size={20} color={selected ? '$tabs/color/tab-fg-selected' : '$tabs/color/tab-dot-selected'} />
        ) : null}
      </XStack>
    </Tabs.Tab>
  );
};

export interface TabsV2Props {
  defaultValue: string;
  value: string;
  onChange: (value: string) => void;
  tabItems: { value: string; label: string; selected: boolean; notification: boolean }[];
  hasBorder?: boolean;
}

// Payment Tab Component for Native 2.0
export const TabV2 = ({ defaultValue, value, onChange, tabItems, hasBorder }: TabsV2Props) => {
  const [activeAt, setActiveAt] = useState<TabLayout | null>(null);
  const setActiveIndicator = (layout: TabLayout) => {
    setActiveAt(layout);
  };

  const handleOnInteraction: TabsTabProps['onInteraction'] = (type, layout) => {
    if (type === 'select' && layout) {
      setActiveIndicator(layout);
    }
  };

  return (
    <Tabs
      defaultValue={defaultValue}
      orientation="horizontal"
      flexDirection="column"
      width="100%"
      // TODO - Investigate ways to not fix the height / different text sizes
      height={44}
      backgroundColor="$background/surface" //TODO change to tabs component token when updated in figma
      paddingHorizontal={getTokenValue('$tabs/space/container-horizontal-padding') as number}
      borderRadius={getTokenValue('$tabs/radius/container-radius') as number}
      justifyContent="center"
      value={value}
      onValueChange={onChange}
      overflow="hidden"
      borderColor={hasBorder ? '$border/surface-subdued' : undefined}
      borderWidth={hasBorder ? 1 : 0}
      activationMode="manual"
      position="relative"
    >
      <YStack>
        <AnimatePresence>
          {activeAt ? (
            <TabsRovingIndicator width={activeAt.width} height={activeAt.height} x={activeAt.x} y={activeAt.y} />
          ) : null}
        </AnimatePresence>

        <Tabs.List disablePassBorderRadius="bottom">
          {tabItems.map((item) => {
            return (
              <TabItem
                key={item.value}
                value={item.value}
                label={item.label}
                selected={item.selected}
                notification={item.notification}
                onInteraction={handleOnInteraction}
              />
            );
          })}
        </Tabs.List>
      </YStack>
    </Tabs>
  );
};

const TabsRovingIndicator = (props: StackProps) => {
  return (
    <YStack
      flex={1}
      position="absolute"
      backgroundColor="$tabs/color/tab-bg-selected"
      borderRadius={getTokenValue('$tabs/radius/tab-radius') as number}
      opacity={1}
      animation="medium"
      {...props}
    />
  );
};

'use client';

import { useDatePickerContext, type DPUserConfig } from '@rehookify/datepicker';
import { ArrowLeft, ArrowRight, Calendar, ChevronLeft, ChevronRight, X } from '@tamagui/lucide-icons';
import dayjs from 'dayjs';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { TamaguiElement, Input as TamaguiInput } from 'tamagui';
import { isWeb, useWindowDimensions, View, XStack, YStack } from 'tamagui';
import { useSafeAreaInsets } from '../../hooks';
import { Button } from '../Button';
import { Elevation } from '../Elevation';
import { H5 } from '../Heading';
import { IconButton } from '../IconButton';
import { Input } from '../Input';
import { Text } from '../Text';
import { DatePickerBase, HeaderTypeProvider, useHeaderType } from './DatePickerBase';
import { swapOnClick } from './helpers';
import { DayPicker, MonthPicker, YearPicker } from './parts';
import type { CalenderContentProps, DatePart, DatePickerProps } from './types';

export const CalendarHeader = () => {
  const { type: header, setHeader } = useHeaderType();
  const {
    data: { calendars, years },
    propGetters: { subtractOffset, addOffset, previousYearsButton, nextYearsButton },
  } = useDatePickerContext();
  const [calendar] = calendars;
  const fromYear = years[0];
  const toYear = years[years.length - 1];

  if (!calendar) {
    return null;
  }

  const { year, month } = calendar;

  const yearSlider = (
    <XStack alignItems="center" gap="$space.lg" justifyContent="center">
      <IconButton
        size="md"
        mode="secondary"
        variant="icon-only"
        {...swapOnClick(previousYearsButton())}
        icon={<ChevronLeft />}
        tabIndex={0}
      />
      <Button
        mode="secondary"
        variant="no-outline"
        onPress={() => {
          setHeader('year');
        }}
        size="md"
        hoverStyle={{ backgroundColor: '$foreground/surface-inverse-subdued' }}
      >
        <Text variant="bodyMediumEm">{`${fromYear?.year} - ${toYear?.year}`}</Text>
      </Button>
      <IconButton
        size="md"
        mode="secondary"
        variant="icon-only"
        {...swapOnClick(nextYearsButton())}
        icon={<ChevronRight />}
        tabIndex={0}
      />
    </XStack>
  );

  const yearSelector = (
    <XStack alignItems="center" gap="$space.lg" justifyContent="center">
      <IconButton
        size="md"
        mode="secondary"
        variant="icon-only"
        {...swapOnClick(subtractOffset({ years: 1 }))}
        icon={<ChevronLeft />}
        tabIndex={0}
      />
      <Button
        mode="secondary"
        variant="no-outline"
        onPress={() => {
          setHeader('year');
        }}
        size="md"
        hoverStyle={{ backgroundColor: '$foreground/surface-inverse-subdued' }}
      >
        <Text variant="bodyMediumEm">{year}</Text>
      </Button>
      <IconButton
        size="md"
        mode="secondary"
        variant="icon-only"
        {...swapOnClick(addOffset({ years: 1 }))}
        icon={<ChevronRight />}
        tabIndex={0}
      />
    </XStack>
  );

  const monthSelector = (
    <XStack justifyContent="space-between" alignItems="center">
      <IconButton
        size="md"
        mode="secondary"
        variant="icon-only"
        {...swapOnClick(subtractOffset({ months: 1 }))}
        icon={<ArrowLeft />}
        tabIndex={0}
      />
      <Button
        mode="secondary"
        variant="no-outline"
        onPress={() => {
          setHeader('month');
        }}
        size="md"
        hoverStyle={{ backgroundColor: '$foreground/surface-inverse-subdued' }}
      >
        <Text variant="bodyMediumEm">{month}</Text>
      </Button>
      <IconButton
        size="md"
        mode="secondary"
        variant="icon-only"
        {...swapOnClick(addOffset({ months: 1 }))}
        icon={<ArrowRight />}
        tabIndex={0}
      />
    </XStack>
  );

  if (header === 'month') {
    return (
      <YStack flex={1} width="100%" gap="$space.md">
        {yearSelector}
        <Text variant="bodyMediumEm" textAlign="center">
          {/* Extract "Select a month" into translations*/}
          Select a month
        </Text>
      </YStack>
    );
  }

  if (header === 'year') {
    return (
      <YStack flex={1} width="100%" gap="$space.md">
        {yearSlider}
      </YStack>
    );
  }

  return (
    <YStack flex={1} width="100%" gap="$space.md">
      {yearSelector}
      {monthSelector}
    </YStack>
  );
};

export const CalenderContent = ({
  title = 'Select a date',
  onSelectionConfirm,
  selectedDate,
  toggleVisibility,
}: CalenderContentProps) => {
  const { bottom } = useSafeAreaInsets();
  const { type: header, setHeader } = useHeaderType();
  const { t } = useTranslation();

  const calender = (
    <View
      flexDirection="column"
      justifyContent="flex-start"
      borderRadius="$radius.2xl"
      gap="$space.md"
      backgroundColor="$background/surface"
      padding="$space.xl"
      $platform-web={{
        maxWidth: 296, // From design - Need a size token
      }}
      $platform-native={{
        paddingTop: '$space.xs',
        paddingHorizontal: '$space.4xl',
        paddingBottom: '$space.xl',
      }}
    >
      <CalendarHeader />
      {header === 'year' && (
        <YearPicker
          onChange={() => {
            setHeader('month');
          }}
        />
      )}
      {header === 'month' && (
        <MonthPicker
          onChange={() => {
            setHeader('day');
          }}
        />
      )}
      {header === 'day' && <DayPicker />}
    </View>
  );

  return isWeb ? (
    <Elevation level={4} shadow="hard" borderRadius="$radius.2xl" backgroundColor="$background/transparent">
      {calender}
    </Elevation>
  ) : (
    <>
      <XStack
        justifyContent="space-between"
        alignItems="center"
        paddingVertical="$space.xs"
        paddingStart="$space.xl"
        paddingEnd="$space.xs"
      >
        {typeof title === 'string' ? <H5>{title}</H5> : title}
        <IconButton variant="icon-only" mode="secondary" size="lg" icon={<X />} onPress={toggleVisibility} />
      </XStack>

      {calender}

      <XStack gap="$space.sm" padding="$space.lg" paddingBottom={bottom} maxWidth="100%" flexWrap="nowrap" flexGrow={1}>
        <Button mode="primary" variant="filled" flexGrow={1} onPress={onSelectionConfirm} disabled={!selectedDate}>
          {t('common.buttons.confirm')}
        </Button>
        <Button mode="primary" variant="outlined" flexGrow={1} onPress={toggleVisibility}>
          {t('common.buttons.cancel')}
        </Button>
      </XStack>
    </>
  );
};

export const DatePicker = function DatePicker(props: DatePickerProps) {
  const { title, config, dateFormat = 'DD/MM/YYYY', onDateSelect, ...rest } = props;

  const windowSizes = useWindowDimensions({});
  const [header, setHeader] = useState<DatePart>('day');
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<'top' | 'bottom'>('top');
  const triggerRef = useRef<TamaguiElement>(null);
  const inputRef = useRef<TamaguiInput>(null);
  const [selectedDate] = selectedDates;

  useLayoutEffect(() => {
    if (triggerRef.current && isWeb) {
      const dims = (triggerRef.current as HTMLElement).getBoundingClientRect();
      const measuredPlacement = windowSizes.height - dims.top > dims.top ? 'bottom' : 'top';

      setPlacement(measuredPlacement);
    }
  }, []);

  useEffect(() => {
    if (isWeb && selectedDate) {
      setOpen(false);
      onDateSelect(dayjs(selectedDate).format(dateFormat));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We want this effect only when selectedDates change
  }, [selectedDates]);

  const datePickerConfig = {
    calendar: {
      mode: 'fluid',
      startDay: 1,
    },
    dates: {
      mode: 'single',
    },
    ...config,
    selectedDates,
    onDatesChange: setSelectedDates,
    focusDate: selectedDate,
  } satisfies DPUserConfig;

  const onConfirm = () => {
    setOpen(false);

    if (selectedDate) {
      onDateSelect(dayjs(selectedDate).format(dateFormat));
    }
    inputRef.current?.blur();
  };

  return (
    <HeaderTypeProvider type={header} setHeader={setHeader}>
      <DatePickerBase
        open={open}
        shouldAdapt={!isWeb}
        onOpenChange={(isOpen) => {
          // - Control focus only on web
          if (!isWeb) {
            setOpen(isOpen);
            return;
          }
          // - Close and focus on input when dismissed without selection
          if (!isOpen && selectedDate) {
            setOpen(isOpen);
            inputRef.current?.focus();
            return;
          }
          // - Allow to open when date not selected
          setOpen(isOpen);
        }}
        config={datePickerConfig}
        placement={placement}
      >
        <DatePickerBase.Trigger ref={triggerRef}>
          <Input
            {...rest}
            ref={inputRef}
            onChangeText={(inputText) => {
              rest.onChangeText?.(inputText);

              if (dayjs(inputText, dateFormat).isValid()) {
                setSelectedDates([dayjs(inputText, dateFormat).toDate()]);
              }
            }}
            onFocus={(e) => {
              if (!isWeb) {
                setOpen(true);
              }
              rest.onFocus?.(e);
            }}
            inputMode={isWeb ? 'numeric' : 'none'}
            prioritiseEndIcon
            endIcon={
              <IconButton
                variant="icon-only"
                mode="secondary"
                onPress={() => {
                  setOpen(true);
                }}
                icon={<Calendar />}
              />
            }
          />
        </DatePickerBase.Trigger>
        <DatePickerBase.Content>
          <DatePickerBase.Content.Arrow size="$md" />
          <CalenderContent
            title={title}
            toggleVisibility={() => {
              setOpen(false);
            }}
            onSelectionConfirm={onConfirm}
            selectedDate={selectedDate}
          />
        </DatePickerBase.Content>
      </DatePickerBase>
    </HeaderTypeProvider>
  );
};

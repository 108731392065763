'use client';

import { getTokenValue, Sheet, YStack } from 'tamagui';
import type { ScrollViewProps } from 'tamagui';
import type { ReactNode } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Platform } from 'react-native';
import { usePageFrameContext } from './PageFrameContext';

export type PageFrameContentProps = {
  /**
   * Main scrollable content that will be grow/shrink as needed.
   */
  children: ReactNode;
  /**
   * Margin above the content. Defaults to $2xl.
   */
  marginTop?: '$2xl' | '$lg';
  /**
   * Whether to use Sheet.ScrollView instead. Defaults to false.
   */
  sheetScrollView?: boolean;
} & ScrollViewProps;

export const PageFrameContent = ({
  children,
  marginTop = '$2xl',
  sheetScrollView = false,
  ...scrollViewProps
}: PageFrameContentProps) => {
  const { footerHeight } = usePageFrameContext();
  const insets = useSafeAreaInsets();

  return (
    <YStack width="100%" flex={1} marginTop={marginTop}>
      {sheetScrollView ? (
        <Sheet.ScrollView width="100%" {...scrollViewProps} asChild>
          <KeyboardAwareScrollView
            bottomOffset={
              // Minimum distance between the keyboard and the bottom of the screen + additional offset depending on the OS
              (getTokenValue(`$space.2xl`) as number) + Platform.OS === 'ios'
                ? 0
                : footerHeight + insets.bottom + insets.top
            }
            contentContainerStyle={{
              paddingHorizontal: getTokenValue(`$space.xl`) as number,
            }}
          >
            {children}
          </KeyboardAwareScrollView>
        </Sheet.ScrollView>
      ) : (
        <KeyboardAwareScrollView
          width="100%"
          {...scrollViewProps}
          bottomOffset={
            // Minimum distance between the keyboard and the bottom of the screen + additional offset depending on the OS
            (getTokenValue(`$space.2xl`) as number) + Platform.OS === 'ios'
              ? 0
              : footerHeight + insets.bottom + insets.top
          }
          contentContainerStyle={{
            paddingHorizontal: getTokenValue(`$space.xl`) as number,
          }}
        >
          {children}
        </KeyboardAwareScrollView>
      )}
    </YStack>
  );
};

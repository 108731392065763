import { useDatePickerContext } from '@rehookify/datepicker';
import { useEffect, useState } from 'react';

export function useDateAnimation({ listenTo }: { listenTo: 'month' }) {
  const {
    data: { calendars },
  } = useDatePickerContext();
  const [currentMonth, setCurrentMonth] = useState<string | null>(null);
  const [calender] = calendars;

  useEffect(() => {
    if (currentMonth !== calender?.month) {
      setCurrentMonth(calender?.month || null);
    }
  }, [calender?.month, currentMonth]);

  const prevNextAnimation = () => {
    if (currentMonth === null) {
      return { enterStyle: { opacity: 0 } };
    }

    const newDate = new Date(`${calender?.[listenTo]} 1, ${calender?.year}`);
    const currentDate = new Date(`${currentMonth} 1, ${calender?.year}`);

    if (currentMonth === 'December' && calender?.month === 'January') {
      return {
        enterStyle: { opacity: 0, x: 15 },
        exitStyle: { opacity: 0, x: 15 },
      };
    }
    if (currentMonth === 'January' && calender?.month === 'December') {
      return {
        enterStyle: { opacity: 0, x: -15 },
        exitStyle: { opacity: 0, x: -15 },
      };
    }
    return {
      enterStyle: { opacity: 0, x: newDate < currentDate ? -15 : 15 },
      exitStyle: { opacity: 0, x: newDate < currentDate ? -15 : 15 },
    };
  };

  return {
    prevNextAnimation,
    prevNextAnimationKey: calender?.[listenTo],
  };
}

'use client';

import { z } from 'zod';
import { Fieldset, YStack } from 'tamagui';
import { Search } from '@tamagui/lucide-icons';
import { useState } from 'react';
import { useTsController } from '@ts-react/form';
import type { DefaultValues } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import type { TypeaheadFieldProps } from '../TypeaheadField';
import { TypeaheadField } from '../TypeaheadField';
import { Input } from '../../../../Input';
import { Button } from '../../../../Button';

export const createInstitutionFieldItemSchema = (params?: z.RawCreateParams) =>
  z.object(
    {
      institution: z.string(),
    },
    params ?? {
      required_error: 'Please select a financial institution',
    }
  );

export type InstitutionFieldSchema = ReturnType<typeof createInstitutionFieldItemSchema>;
export type InstitutionFieldItem = z.infer<InstitutionFieldSchema>;
export type InstitutionFieldProps = TypeaheadFieldProps<InstitutionFieldItem>;

export function InstitutionField(props: InstitutionFieldProps): JSX.Element {
  const { field, error, formState } = useTsController<InstitutionFieldItem>();
  const form = useFormContext();
  const [mode, setMode] = useState<'search' | 'manual'>('search');

  const initialSearchValue =
    (formState.defaultValues?.[field.name] as DefaultValues<InstitutionFieldItem> | undefined)?.institution ?? '';

  const extraItemsWithManualEntry = [
    ...(props.extraItems ?? []),
    {
      label: `Can't find your institution?`,
      onPress: () => {
        setMode('manual');
        form.setValue(field.name, {
          type: 'manual',
        });
      },
    },
  ];

  return mode === 'search' ? (
    <Fieldset>
      <TypeaheadField<InstitutionFieldItem>
        {...props}
        initialSearchValue={initialSearchValue}
        extraItems={extraItemsWithManualEntry}
      />
    </Fieldset>
  ) : (
    <YStack gap="$lg">
      <Fieldset>
        <Input
          testID={`${field.name}-institution-input`}
          size="$lg"
          disabled={props.disabled}
          inputMode="text"
          onChangeText={(text) => {
            field.onChange({
              institution: text,
            });
          }}
          label="Institution name"
          value={field.value?.institution ?? ''}
          error={error?.institution?.errorMessage}
        />
      </Fieldset>
      <YStack alignItems="flex-start">
        <Button
          startIcon={<Search />}
          disabled={props.disabled}
          variant="no-outline"
          mode="primary"
          size="sm"
          onPress={() => {
            setMode('search');
            form.setValue(field.name, undefined);
          }}
        >
          Search institution instead
        </Button>
      </YStack>
    </YStack>
  );
}

'use client';
import { useTransition } from 'react';
import { MultiStepForm } from '../MultiStepForm';
import { mapBEStepsToMultiFormSteps } from './mapBeStepsToMultiFormSteps';
import type { CDStep, ComponentMap, ComponentSubmitDataId } from './types';

interface ConfigDrivenMultiStepFormProps {
  flowId: string;
  componentMap: ComponentMap;
  beSteps: CDStep[];
  onFormSubmit: () => Promise<void> | void;
  onComponentSubmit: ComponentSubmitDataId;
  onTaskComplete: () => Promise<void>;
  onClose?: () => void;
  onPressLogo?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any -- application depends on the flow
  application: any;
  disclaimer?: string;
}
export function ConfigDrivenMultiStepForm({
  flowId,
  componentMap,
  beSteps,
  onFormSubmit,
  onComponentSubmit,
  onTaskComplete,
  onClose,
  onPressLogo,
  application,
  disclaimer,
}: ConfigDrivenMultiStepFormProps) {
  const [isSubmitting, startTransition] = useTransition();
  const onSubmit = () => {
    startTransition(async () => {
      await onFormSubmit();
    });
  };

  const steps = mapBEStepsToMultiFormSteps({
    flowId,
    componentMap,
    beSteps,
    onComponentSubmit,
    application,
    disabled: isSubmitting,
    isSubmitting,
    onTaskComplete,
  });

  return (
    <MultiStepForm
      steps={steps}
      onSubmit={onSubmit}
      onClose={onClose}
      onPressLogo={onPressLogo}
      disclaimer={disclaimer}
    />
  );
}

// THIS IS AN AUTOGENERATED FILE. REGENERATE USING THE TOKENS2CODE FIGMA PLUGIN

import type { VariablesCollection } from '../variables';
import { coreTokensColor, coreTokensRadius, coreTokensSize, coreTokensSpace } from './coreTokens';

export const semanticTokensLightColor = {
  'background/app': coreTokensColor['neutral/1'],
  'background/danger': coreTokensColor['danger/2'],
  'background/danger-hover': coreTokensColor['danger/1'],
  'background/danger-press': coreTokensColor['danger/3'],
  'background/disabled': coreTokensColor['neutral/3'],
  'background/info': coreTokensColor['info/2'],
  'background/neutral': coreTokensColor['neutral/2'],
  'background/primary-default': coreTokensColor['primary/6'],
  'background/primary-hover': coreTokensColor['primary/5'],
  'background/primary-press': coreTokensColor['primary/7'],
  'background/primary-subdued': coreTokensColor['primary/1'],
  'background/secondary-default': coreTokensColor['secondary/10'],
  'background/secondary-hover': coreTokensColor['secondary/9'],
  'background/secondary-press': coreTokensColor['secondary/11'],
  'background/secondary-subdued': coreTokensColor['secondary/8'],
  'background/success': coreTokensColor['success/1'],
  'background/surface': coreTokensColor['white/alpha-100'],
  'background/surface-highlight': coreTokensColor['neutral/2'],
  'background/surface-hover': coreTokensColor['white/alpha-100'],
  'background/surface-inverse': coreTokensColor['neutral/11'],
  'background/surface-press': coreTokensColor['white/alpha-100'],
  'background/tertiary-default': coreTokensColor['tertiary/7'],
  'background/tertiary-hover': coreTokensColor['tertiary/6'],
  'background/tertiary-press': coreTokensColor['tertiary/8'],
  'background/tertiary-subdued': coreTokensColor['tertiary/2'],
  'background/transparent': coreTokensColor['white/alpha-0'],
  'background/warning': coreTokensColor['warning/2'],
  'border/app': coreTokensColor['neutral/6'],
  'border/app-subdued': coreTokensColor['neutral/2'],
  'border/danger': coreTokensColor['danger/6'],
  'border/danger-hover': coreTokensColor['danger/5'],
  'border/danger-press': coreTokensColor['danger/7'],
  'border/disabled': coreTokensColor['neutral/6'],
  'border/info': coreTokensColor['info/7'],
  'border/primary': coreTokensColor['primary/10'],
  'border/primary-hover': coreTokensColor['primary/9'],
  'border/primary-press': coreTokensColor['primary/11'],
  'border/primary-subdued': coreTokensColor['primary/3'],
  'border/secondary': coreTokensColor['secondary/8'],
  'border/secondary-hover': coreTokensColor['secondary/7'],
  'border/secondary-press': coreTokensColor['secondary/9'],
  'border/secondary-subdued': coreTokensColor['secondary/3'],
  'border/success': coreTokensColor['success/6'],
  'border/surface': coreTokensColor['neutral/6'],
  'border/surface-inverse': coreTokensColor['neutral/1'],
  'border/surface-subdued': coreTokensColor['neutral/2'],
  'border/tertiary': coreTokensColor['tertiary/8'],
  'border/tertiary-hover': coreTokensColor['tertiary/7'],
  'border/tertiary-press': coreTokensColor['tertiary/9'],
  'border/tertiary-subdued': coreTokensColor['tertiary/3'],
  'border/warning': coreTokensColor['warning/7'],
  'carousel-stepper/default': coreTokensColor['secondary/6'],
  'carousel-stepper/selected': coreTokensColor['primary/12'],
  'chart-background-subdued/alpha': coreTokensColor['alpha/1'],
  'chart-background-subdued/beta': coreTokensColor['beta/1'],
  'chart-background-subdued/delta': coreTokensColor['delta/1'],
  'chart-background-subdued/epsilon': coreTokensColor['epsilon/2'],
  'chart-background-subdued/eta': coreTokensColor['eta/2'],
  'chart-background-subdued/gamma': coreTokensColor['gamma/1'],
  'chart-background-subdued/iota': coreTokensColor['iota/2'],
  'chart-background-subdued/theta': coreTokensColor['theta/2'],
  'chart-background-subdued/zeta': coreTokensColor['zeta/1'],
  'chart-background/alpha': coreTokensColor['alpha/6'],
  'chart-background/beta': coreTokensColor['beta/8'],
  'chart-background/delta': coreTokensColor['delta/6'],
  'chart-background/epsilon': coreTokensColor['epsilon/7'],
  'chart-background/eta': coreTokensColor['eta/6'],
  'chart-background/gamma': coreTokensColor['gamma/11'],
  'chart-background/iota': coreTokensColor['iota/6'],
  'chart-background/theta': coreTokensColor['theta/4'],
  'chart-background/zeta': coreTokensColor['zeta/7'],
  'chart-border/alpha': coreTokensColor['alpha/6'],
  'chart-border/beta': coreTokensColor['beta/7'],
  'chart-border/delta': coreTokensColor['delta/6'],
  'chart-border/epsilon': coreTokensColor['epsilon/7'],
  'chart-border/eta': coreTokensColor['eta/6'],
  'chart-border/gamma': coreTokensColor['gamma/10'],
  'chart-border/iota': coreTokensColor['iota/6'],
  'chart-border/theta': coreTokensColor['theta/4'],
  'chart-border/zeta': coreTokensColor['zeta/7'],
  'chart-foreground-subdued/alpha': coreTokensColor['alpha/8'],
  'chart-foreground-subdued/beta': coreTokensColor['beta/10'],
  'chart-foreground-subdued/delta': coreTokensColor['delta/9'],
  'chart-foreground-subdued/epsilon': coreTokensColor['epsilon/9'],
  'chart-foreground-subdued/eta': coreTokensColor['eta/9'],
  'chart-foreground-subdued/gamma': coreTokensColor['gamma/11'],
  'chart-foreground-subdued/iota': coreTokensColor['iota/10'],
  'chart-foreground-subdued/theta': coreTokensColor['theta/9'],
  'chart-foreground-subdued/zeta': coreTokensColor['zeta/9'],
  'chart-foreground/alpha': coreTokensColor['alpha/12'],
  'chart-foreground/beta': coreTokensColor['beta/12'],
  'chart-foreground/delta': coreTokensColor['delta/12'],
  'chart-foreground/epsilon': coreTokensColor['epsilon/12'],
  'chart-foreground/eta': coreTokensColor['eta/12'],
  'chart-foreground/gamma': coreTokensColor['gamma/12'],
  'chart-foreground/iota': coreTokensColor['iota/12'],
  'chart-foreground/theta': coreTokensColor['theta/12'],
  'chart-foreground/zeta': coreTokensColor['zeta/12'],
  'chart-icon/alpha': coreTokensColor['alpha/8'],
  'chart-icon/beta': coreTokensColor['beta/10'],
  'chart-icon/delta': coreTokensColor['delta/9'],
  'chart-icon/epsilon': coreTokensColor['epsilon/9'],
  'chart-icon/eta': coreTokensColor['eta/9'],
  'chart-icon/gamma': coreTokensColor['gamma/11'],
  'chart-icon/iota': coreTokensColor['iota/10'],
  'chart-icon/theta': coreTokensColor['theta/9'],
  'chart-icon/zeta': coreTokensColor['zeta/9'],
  'foreground/app-default': coreTokensColor['neutral/12'],
  'foreground/app-subdued': coreTokensColor['neutral/8'],
  'foreground/danger-default': coreTokensColor['danger/10'],
  'foreground/danger-subdued': coreTokensColor['danger/8'],
  'foreground/disabled': coreTokensColor['neutral/9'],
  'foreground/info-default': coreTokensColor['info/10'],
  'foreground/info-subdued': coreTokensColor['info/9'],
  'foreground/neutral': coreTokensColor['neutral/9'],
  'foreground/primary-default': coreTokensColor['primary/12'],
  'foreground/primary-subdued': coreTokensColor['primary/12'],
  'foreground/secondary-default': coreTokensColor['white/alpha-100'],
  'foreground/secondary-subdued': coreTokensColor['secondary/4'],
  'foreground/success-default': coreTokensColor['success/9'],
  'foreground/success-subdued': coreTokensColor['success/8'],
  'foreground/surface-default': coreTokensColor['neutral/12'],
  'foreground/surface-inverse': coreTokensColor['neutral/1'],
  'foreground/surface-inverse-subdued': coreTokensColor['neutral/4'],
  'foreground/surface-subdued': coreTokensColor['neutral/7'],
  'foreground/tertiary-default': coreTokensColor['tertiary/12'],
  'foreground/tertiary-subdued': coreTokensColor['tertiary/10'],
  'foreground/warning-default': coreTokensColor['warning/10'],
  'foreground/warning-subdued': coreTokensColor['warning/9'],
  'icon/accent': coreTokensColor['accent/8'],
  'icon/accent-hover': coreTokensColor['accent/7'],
  'icon/accent-press': coreTokensColor['accent/9'],
  'icon/app': coreTokensColor['neutral/12'],
  'icon/app-subdued': coreTokensColor['neutral/8'],
  'icon/danger': coreTokensColor['danger/6'],
  'icon/disabled': coreTokensColor['neutral/6'],
  'icon/info': coreTokensColor['info/7'],
  'icon/primary': coreTokensColor['primary/10'],
  'icon/primary-hover': coreTokensColor['primary/9'],
  'icon/primary-press': coreTokensColor['primary/11'],
  'icon/success': coreTokensColor['success/6'],
  'icon/surface': coreTokensColor['neutral/12'],
  'icon/surface-inverse': coreTokensColor['neutral/1'],
  'icon/surface-inverse-subdued': coreTokensColor['neutral/4'],
  'icon/surface-subdued': coreTokensColor['neutral/7'],
  'icon/warning': coreTokensColor['warning/7'],
  'other/accent': coreTokensColor['accent/8'],
  'other/black-alpha-50': coreTokensColor['black/alpha-50'],
  'other/black-alpha-60': coreTokensColor['black/alpha-60'],
  'other/black-alpha-100': coreTokensColor['black/alpha-100'],
  'other/data-vis-negative': coreTokensColor['tertiary/8'],
  'other/data-vis-positive': coreTokensColor['primary/9'],
  'other/focus': coreTokensColor['accent/6'],
  'other/white-alpha-50': coreTokensColor['white/alpha-50'],
  'other/white-alpha-60': coreTokensColor['white/alpha-60'],
  'other/white-alpha-100': coreTokensColor['white/alpha-100'],
  'text/app': coreTokensColor['neutral/12'],
  'text/app-subdued': coreTokensColor['neutral/8'],
  'text/danger': coreTokensColor['danger/8'],
  'text/danger-hover': coreTokensColor['danger/7'],
  'text/danger-press': coreTokensColor['danger/9'],
  'text/disabled': coreTokensColor['neutral/7'],
  'text/info': coreTokensColor['info/8'],
  'text/primary': coreTokensColor['primary/11'],
  'text/primary-hover': coreTokensColor['primary/10'],
  'text/primary-press': coreTokensColor['primary/12'],
  'text/secondary': coreTokensColor['secondary/8'],
  'text/secondary-hover': coreTokensColor['secondary/7'],
  'text/secondary-press': coreTokensColor['secondary/9'],
  'text/success': coreTokensColor['success/8'],
  'text/surface': coreTokensColor['neutral/12'],
  'text/surface-inverse': coreTokensColor['neutral/1'],
  'text/surface-inverse-subdued': coreTokensColor['neutral/4'],
  'text/surface-subdued': coreTokensColor['neutral/7'],
  'text/tertiary': coreTokensColor['tertiary/11'],
  'text/tertiary-hover': coreTokensColor['tertiary/10'],
  'text/tertiary-press': coreTokensColor['tertiary/12'],
  'text/warning': coreTokensColor['warning/8'],
} as const;
type SemanticTokensBaseTheme = VariablesCollection<typeof semanticTokensLightColor>;

export const semanticTokensDarkColor: SemanticTokensBaseTheme = {
  'background/app': coreTokensColor['neutral/11'],
  'background/danger': coreTokensColor['danger/4'],
  'background/danger-hover': coreTokensColor['danger/3'],
  'background/danger-press': coreTokensColor['danger/5'],
  'background/disabled': coreTokensColor['neutral/9'],
  'background/info': coreTokensColor['info/4'],
  'background/neutral': coreTokensColor['neutral/8'],
  'background/primary-default': coreTokensColor['primary/7'],
  'background/primary-hover': coreTokensColor['primary/6'],
  'background/primary-press': coreTokensColor['primary/8'],
  'background/primary-subdued': coreTokensColor['primary/12'],
  'background/secondary-default': coreTokensColor['secondary/11'],
  'background/secondary-hover': coreTokensColor['secondary/10'],
  'background/secondary-press': coreTokensColor['secondary/12'],
  'background/secondary-subdued': coreTokensColor['secondary/9'],
  'background/success': coreTokensColor['success/3'],
  'background/surface': coreTokensColor['neutral/10'],
  'background/surface-highlight': coreTokensColor['neutral/9'],
  'background/surface-hover': coreTokensColor['neutral/10'],
  'background/surface-inverse': coreTokensColor['neutral/1'],
  'background/surface-press': coreTokensColor['neutral/10'],
  'background/tertiary-default': coreTokensColor['tertiary/8'],
  'background/tertiary-hover': coreTokensColor['tertiary/7'],
  'background/tertiary-press': coreTokensColor['tertiary/9'],
  'background/tertiary-subdued': coreTokensColor['tertiary/9'],
  'background/transparent': coreTokensColor['black/alpha-0'],
  'background/warning': coreTokensColor['warning/3'],
  'border/app': coreTokensColor['neutral/7'],
  'border/app-subdued': coreTokensColor['neutral/9'],
  'border/danger': coreTokensColor['danger/5'],
  'border/danger-hover': coreTokensColor['danger/4'],
  'border/danger-press': coreTokensColor['danger/6'],
  'border/disabled': coreTokensColor['neutral/6'],
  'border/info': coreTokensColor['info/6'],
  'border/primary': coreTokensColor['primary/8'],
  'border/primary-hover': coreTokensColor['primary/7'],
  'border/primary-press': coreTokensColor['primary/9'],
  'border/primary-subdued': coreTokensColor['primary/10'],
  'border/secondary': coreTokensColor['secondary/5'],
  'border/secondary-hover': coreTokensColor['secondary/4'],
  'border/secondary-press': coreTokensColor['secondary/6'],
  'border/secondary-subdued': coreTokensColor['secondary/8'],
  'border/success': coreTokensColor['success/6'],
  'border/surface': coreTokensColor['neutral/7'],
  'border/surface-inverse': coreTokensColor['neutral/11'],
  'border/surface-subdued': coreTokensColor['neutral/9'],
  'border/tertiary': coreTokensColor['tertiary/8'],
  'border/tertiary-hover': coreTokensColor['tertiary/7'],
  'border/tertiary-press': coreTokensColor['tertiary/9'],
  'border/tertiary-subdued': coreTokensColor['tertiary/10'],
  'border/warning': coreTokensColor['warning/6'],
  'carousel-stepper/default': coreTokensColor['alpha/5'],
  'carousel-stepper/selected': coreTokensColor['beta/3'],
  'chart-background-subdued/alpha': coreTokensColor['alpha/12'],
  'chart-background-subdued/beta': coreTokensColor['beta/12'],
  'chart-background-subdued/delta': coreTokensColor['delta/12'],
  'chart-background-subdued/epsilon': coreTokensColor['epsilon/11'],
  'chart-background-subdued/eta': coreTokensColor['eta/11'],
  'chart-background-subdued/gamma': coreTokensColor['gamma/12'],
  'chart-background-subdued/iota': coreTokensColor['iota/11'],
  'chart-background-subdued/theta': coreTokensColor['theta/11'],
  'chart-background-subdued/zeta': coreTokensColor['zeta/12'],
  'chart-background/alpha': coreTokensColor['alpha/6'],
  'chart-background/beta': coreTokensColor['beta/5'],
  'chart-background/delta': coreTokensColor['delta/3'],
  'chart-background/epsilon': coreTokensColor['epsilon/4'],
  'chart-background/eta': coreTokensColor['eta/3'],
  'chart-background/gamma': coreTokensColor['gamma/2'],
  'chart-background/iota': coreTokensColor['iota/3'],
  'chart-background/theta': coreTokensColor['theta/3'],
  'chart-background/zeta': coreTokensColor['zeta/4'],
  'chart-border/alpha': coreTokensColor['alpha/3'],
  'chart-border/beta': coreTokensColor['beta/6'],
  'chart-border/delta': coreTokensColor['delta/3'],
  'chart-border/epsilon': coreTokensColor['epsilon/4'],
  'chart-border/eta': coreTokensColor['eta/3'],
  'chart-border/gamma': coreTokensColor['gamma/3'],
  'chart-border/iota': coreTokensColor['iota/3'],
  'chart-border/theta': coreTokensColor['theta/3'],
  'chart-border/zeta': coreTokensColor['zeta/4'],
  'chart-foreground-subdued/alpha': coreTokensColor['alpha/5'],
  'chart-foreground-subdued/beta': coreTokensColor['beta/3'],
  'chart-foreground-subdued/delta': coreTokensColor['delta/4'],
  'chart-foreground-subdued/epsilon': coreTokensColor['epsilon/4'],
  'chart-foreground-subdued/eta': coreTokensColor['eta/4'],
  'chart-foreground-subdued/gamma': coreTokensColor['gamma/2'],
  'chart-foreground-subdued/iota': coreTokensColor['iota/4'],
  'chart-foreground-subdued/theta': coreTokensColor['theta/3'],
  'chart-foreground-subdued/zeta': coreTokensColor['zeta/4'],
  'chart-foreground/alpha': coreTokensColor['alpha/1'],
  'chart-foreground/beta': coreTokensColor['beta/1'],
  'chart-foreground/delta': coreTokensColor['delta/1'],
  'chart-foreground/epsilon': coreTokensColor['epsilon/1'],
  'chart-foreground/eta': coreTokensColor['eta/1'],
  'chart-foreground/gamma': coreTokensColor['gamma/1'],
  'chart-foreground/iota': coreTokensColor['iota/1'],
  'chart-foreground/theta': coreTokensColor['theta/8'],
  'chart-foreground/zeta': coreTokensColor['zeta/1'],
  'chart-icon/alpha': coreTokensColor['alpha/5'],
  'chart-icon/beta': coreTokensColor['beta/3'],
  'chart-icon/delta': coreTokensColor['delta/4'],
  'chart-icon/epsilon': coreTokensColor['epsilon/4'],
  'chart-icon/eta': coreTokensColor['eta/4'],
  'chart-icon/gamma': coreTokensColor['gamma/2'],
  'chart-icon/iota': coreTokensColor['iota/4'],
  'chart-icon/theta': coreTokensColor['theta/3'],
  'chart-icon/zeta': coreTokensColor['zeta/4'],
  'foreground/app-default': coreTokensColor['neutral/1'],
  'foreground/app-subdued': coreTokensColor['neutral/4'],
  'foreground/danger-default': coreTokensColor['danger/11'],
  'foreground/danger-subdued': coreTokensColor['danger/10'],
  'foreground/disabled': coreTokensColor['neutral/3'],
  'foreground/info-default': coreTokensColor['info/11'],
  'foreground/info-subdued': coreTokensColor['info/10'],
  'foreground/neutral': coreTokensColor['neutral/2'],
  'foreground/primary-default': coreTokensColor['primary/12'],
  'foreground/primary-subdued': coreTokensColor['primary/1'],
  'foreground/secondary-default': coreTokensColor['white/alpha-100'],
  'foreground/secondary-subdued': coreTokensColor['secondary/4'],
  'foreground/success-default': coreTokensColor['success/10'],
  'foreground/success-subdued': coreTokensColor['success/9'],
  'foreground/surface-default': coreTokensColor['neutral/1'],
  'foreground/surface-inverse': coreTokensColor['neutral/12'],
  'foreground/surface-inverse-subdued': coreTokensColor['neutral/7'],
  'foreground/surface-subdued': coreTokensColor['neutral/4'],
  'foreground/tertiary-default': coreTokensColor['tertiary/12'],
  'foreground/tertiary-subdued': coreTokensColor['tertiary/11'],
  'foreground/warning-default': coreTokensColor['warning/11'],
  'foreground/warning-subdued': coreTokensColor['warning/10'],
  'icon/accent': coreTokensColor['accent/5'],
  'icon/accent-hover': coreTokensColor['accent/4'],
  'icon/accent-press': coreTokensColor['accent/6'],
  'icon/app': coreTokensColor['neutral/1'],
  'icon/app-subdued': coreTokensColor['neutral/6'],
  'icon/danger': coreTokensColor['danger/4'],
  'icon/disabled': coreTokensColor['neutral/6'],
  'icon/info': coreTokensColor['info/5'],
  'icon/primary': coreTokensColor['primary/8'],
  'icon/primary-hover': coreTokensColor['primary/7'],
  'icon/primary-press': coreTokensColor['primary/9'],
  'icon/success': coreTokensColor['success/5'],
  'icon/surface': coreTokensColor['neutral/1'],
  'icon/surface-inverse': coreTokensColor['neutral/12'],
  'icon/surface-inverse-subdued': coreTokensColor['neutral/7'],
  'icon/surface-subdued': coreTokensColor['neutral/6'],
  'icon/warning': coreTokensColor['warning/6'],
  'other/accent': coreTokensColor['accent/5'],
  'other/black-alpha-50': coreTokensColor['black/alpha-50'],
  'other/black-alpha-60': coreTokensColor['black/alpha-60'],
  'other/black-alpha-100': coreTokensColor['black/alpha-100'],
  'other/data-vis-negative': coreTokensColor['tertiary/6'],
  'other/data-vis-positive': coreTokensColor['primary/7'],
  'other/focus': coreTokensColor['accent/7'],
  'other/white-alpha-50': coreTokensColor['black/alpha-50'],
  'other/white-alpha-60': coreTokensColor['black/alpha-60'],
  'other/white-alpha-100': coreTokensColor['black/alpha-100'],
  'text/app': coreTokensColor['neutral/1'],
  'text/app-subdued': coreTokensColor['neutral/4'],
  'text/danger': coreTokensColor['danger/4'],
  'text/danger-hover': coreTokensColor['danger/3'],
  'text/danger-press': coreTokensColor['danger/5'],
  'text/disabled': coreTokensColor['neutral/5'],
  'text/info': coreTokensColor['info/5'],
  'text/primary': coreTokensColor['primary/7'],
  'text/primary-hover': coreTokensColor['primary/6'],
  'text/primary-press': coreTokensColor['primary/8'],
  'text/secondary': coreTokensColor['secondary/2'],
  'text/secondary-hover': coreTokensColor['secondary/1'],
  'text/secondary-press': coreTokensColor['secondary/3'],
  'text/success': coreTokensColor['success/5'],
  'text/surface': coreTokensColor['neutral/1'],
  'text/surface-inverse': coreTokensColor['neutral/12'],
  'text/surface-inverse-subdued': coreTokensColor['neutral/7'],
  'text/surface-subdued': coreTokensColor['neutral/4'],
  'text/tertiary': coreTokensColor['tertiary/7'],
  'text/tertiary-hover': coreTokensColor['tertiary/6'],
  'text/tertiary-press': coreTokensColor['tertiary/8'],
  'text/warning': coreTokensColor['warning/6'],
} as const;

export const semanticTokensColor = {
  light: semanticTokensLightColor,
  dark: semanticTokensDarkColor,
} as const;

export const semanticTokensSpace = {
  '2xl': coreTokensSpace['8'],
  '2xs': coreTokensSpace['1'],
  '3xl': coreTokensSpace['10'],
  '4xl': coreTokensSpace['12'],
  '5xl': coreTokensSpace['14'],
  '6xl': coreTokensSpace['16'],
  '7xl': coreTokensSpace['20'],
  '8xl': coreTokensSpace['24'],
  '9xl': coreTokensSpace['28'],
  lg: coreTokensSpace['3-5'],
  md: coreTokensSpace['3'],
  sm: coreTokensSpace['2'],
  'sm-md': coreTokensSpace['2-5'],
  xl: coreTokensSpace['5'],
  xs: coreTokensSpace['1-5'],
};

export const semanticTokensSize = {
  '2xl': coreTokensSize['3-5'],
  '2xs': coreTokensSize['0-5'],
  '3xl': coreTokensSize['4'],
  '3xs': coreTokensSize['0-25'],
  '4xl': coreTokensSize['4-5'],
  '4xs': coreTokensSize['0-12-5'],
  '5xl': coreTokensSize['5'],
  '6xl': coreTokensSize['6'],
  '7xl': coreTokensSize['7'],
  '8xl': coreTokensSize['8'],
  '9xl': coreTokensSize['9'],
  '20xl': coreTokensSize['20'],
  lg: coreTokensSize['2-5'],
  md: coreTokensSize['2'],
  'perc-50': coreTokensSize['50_percent'],
  'perc-62-5': coreTokensSize['62-5_percent'],
  'perc-75': coreTokensSize['75_percent'],
  'perc-100': coreTokensSize['100_percent'],
  sm: coreTokensSize['1-5'],
  xl: coreTokensSize['3'],
  xs: coreTokensSize['1'],
};

export const semanticTokensRadius = {
  '2xl': coreTokensRadius['6'],
  '3xl': coreTokensRadius['7'],
  '4xl': coreTokensRadius['8'],
  '5xl': coreTokensRadius['9'],
  '6xl': coreTokensRadius['10'],
  '7xl': coreTokensRadius['12'],
  '8xl': coreTokensRadius['14'],
  lg: coreTokensRadius['4'],
  md: coreTokensRadius['3'],
  round: coreTokensRadius['1000'],
  sm: coreTokensRadius['2'],
  square: coreTokensRadius['0'],
  xl: coreTokensRadius['5'],
  xs: coreTokensRadius['1'],
};

import type { ReactElement } from 'react';
import { isWeb, Sheet, YStack } from 'tamagui';
import type { ScrollViewProps, YStackProps } from 'tamagui';
import { Dimensions } from 'react-native';
import { useSafeAreaInsets } from '../../hooks';
import { Portal } from '../Portal';
import { AnimatePresence } from '../AnimatePresence';

export const FULLSCREEN_OVERLAY_TEST_ID = 'full-screen-overlay';

export interface FullScreenOverlayBaseProps {
  children: ReactElement | ReactElement[] | null;
  visible: boolean;
  overlayProps?: YStackProps;
  scrollViewProps?: ScrollViewProps;
  contentContainerProps?: YStackProps;
}

const windowHeight = isWeb ? '100dvh' : Dimensions.get('window').height;

export const FullScreenOverlayBase = ({
  visible,
  children,
  overlayProps,
  scrollViewProps,
  contentContainerProps = {},
}: FullScreenOverlayBaseProps) => {
  const insets = useSafeAreaInsets();

  return (
    <Portal
      marginTop={-insets.top} // Hack to set a max height below insets in native
      {...(isWeb && visible ? { pointerEvents: 'auto' } : {})}
    >
      <Sheet
        unmountChildrenWhenHidden
        open={visible}
        disableDrag
        snapPoints={[100]}
        dismissOnSnapToBottom
        modal={false}
      >
        <Sheet.Frame
          justifyContent="center"
          alignItems="center"
          height={windowHeight}
          animation="250ms"
          display="flex"
          paddingTop={insets.top}
          testID={FULLSCREEN_OVERLAY_TEST_ID}
          {...overlayProps}
        >
          <Sheet.ScrollView width="100%" height="100%" {...scrollViewProps}>
            {/* Prevents children from rendering if they're not on the screen */}
            <AnimatePresence>
              {visible ? (
                <YStack
                  enterStyle={{ opacity: 0 }}
                  opacity={1}
                  exitStyle={{ opacity: 0 }}
                  animation="250ms"
                  {...contentContainerProps}
                >
                  {children}
                </YStack>
              ) : null}
            </AnimatePresence>
          </Sheet.ScrollView>
        </Sheet.Frame>
      </Sheet>
    </Portal>
  );
};
